<div class="items-center space-x-3 group" [ngClass]="getWrapperClasses()">
  <div class="items-center justify-center shrink-0" [ngClass]="getButtonClasses()">
    <div class="flex items-center text-left" [ngClass]="getContentClasses()">
      <div class="shrink-0" *ngIf="isIconContainerVisible()" [ngClass]="getIconClasses()">
        <ng-container *ngIf="!loading">
          <wkw-icon *ngIf="icon" [size]="iconSize" [name]="icon"></wkw-icon>
        </ng-container>
        <wkw-spinner *ngIf="loading" [size]="iconSize"></wkw-spinner>
      </div>
      <div>
        <ng-content></ng-content>
      </div>
    </div>
  </div>
</div>
