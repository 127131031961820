import { Component, HostBinding, Input, OnInit } from '@angular/core';
import {
  WkwButtonIconPos,
  WkwButtonSize,
  WkwButtonVariant,
  WkwIcon,
  WkwIconSize,
  WkwTheme,
} from '@wkw-types';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'button[wkw-button], a[wkw-button]',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss'],
})
export class ButtonComponent implements OnInit {
  @Input() variant: WkwButtonVariant = 'flat';
  @Input() icon?: WkwIcon;
  @Input() iconPos: WkwButtonIconPos = 'left';
  @Input() iconSize: WkwIconSize = 16;
  @Input() size: WkwButtonSize = 'M';
  @Input() loading = false;
  @Input() theme: WkwTheme = 'on-light';

  @HostBinding() @Input() isDisabled = false;
  @HostBinding('class.is-elastic') @Input() elastic = false;
  @HostBinding('class.is-full') @Input() full = false;

  @HostBinding('attr.disabled') get disabled(): boolean | null {
    return this.isDisabled || null;
  }

  ngOnInit(): void {
    if (this.variant === 'text') {
      this.icon = 'chevron-right-16';
      this.iconPos = 'right';
    }
  }

  public getWrapperClasses(): string[] {
    const classes = [];

    if (this.isDisabled) {
      classes.push('cursor-not-allowed');
    }

    if (this.elastic) {
      classes.push('flex', 'sm:inline-flex');
    } else if (this.full) {
      classes.push('w-full');
    } else {
      classes.push('inline-flex');
    }

    return classes;
  }

  public getButtonClasses(): string[] {
    const classes = ['transition-all', 'duration-200', 'ease-in-out'];

    if (this.elastic) {
      classes.push('flex', 'w-full', 'sm:inline-flex');
      if (this.variant === 'link') {
        classes.push('py-2.5', 'sm:py-0');
      }
    } else if (this.full) {
      classes.push('flex');
    } else {
      classes.push('inline-flex');
    }

    if (
      this.variant !== 'link' &&
      this.variant !== 'text' &&
      this.variant !== 'footer-link'
    ) {
      classes.push('border', 'rounded-full');

      if (this.theme === 'on-dark') {
        classes.push('border-white');
      } else {
        classes.push('border-black');
      }
    }

    if (this.variant === 'link') {
      classes.push('border-b', 'border-purple-500', 'text-purple-500', 'px-px');
      if (!this.isDisabled) {
        if (this.elastic) {
          classes.push(
            'sm:group-hover:border-purple-500',
            'sm:group-hover:bg-purple-100'
          );
        } else {
          classes.push(
            'group-hover:border-purple-500',
            'group-hover:bg-purple-100'
          );
        }
      }
    }

    if (this.variant === 'footer-link') {
      classes.push('border-b', 'border-transparent', 'px-px', 'text-black');
      if (!this.isDisabled) {
        if (this.elastic) {
          classes.push(
            'sm:group-hover:border-purple-500',
            'sm:group-hover:text-purple-500'
          );
        } else {
          classes.push(
            'group-hover:border-purple-500',
            'sm:group-hover:text-purple-500'
          );
        }
      }
    }

    if (this.variant === 'text') {
      classes.push(
        'text-black',
        'px-px',
        'font-medium',
        'border-b',
        'border-transparent'
      );
      if (!this.isDisabled) {
        if (this.elastic) {
          classes.push(
            'sm:group-hover:text-purple-500',
            'sm:group-hover:border-purple-500'
          );
        } else {
          classes.push(
            'group-hover:text-purple-500',
            'group-hover:border-purple-500'
          );
        }
      }
    }

    if (this.variant === 'flat' || this.variant === 'outline') {
      classes.push('px-6', 'py-2.5', 'leading-none');
    } else if (this.variant === 'tag') {
      classes.push('px-6', 'py-1.5', 'leading-none');
    }

    switch (this.variant) {
      case 'flat':
        if (this.theme === 'on-dark') {
          classes.push('bg-white', 'text-black', 'font-medium');
          if (!this.isDisabled) {
            classes.push(
              'group-hover:bg-gray-100',
              'group-hover:text-black',
              'group-hover:border-gray-300'
            );
          }
        } else {
          classes.push('bg-black', 'text-white', 'font-medium');
          if (!this.isDisabled) {
            classes.push(
              'group-hover:bg-gray-100',
              'group-hover:border-gray-300',
              'group-hover:text-black'
            );
          }
        }

        break;
      case 'outline':
        if (this.theme === 'on-dark') {
          classes.push('bg-transparent', 'text-white', 'font-medium');
          if (!this.isDisabled) {
            classes.push('group-hover:bg-gray-100');
          }
        } else {
          classes.push('bg-transparent', 'text-black', 'font-medium');
          if (!this.isDisabled) {
            classes.push(
              'group-hover:bg-gray-100',
              'group-hover:border-gray-300',
              'group-hover:text-black'
            );
          }
        }

        break;

      case 'tag':
        if (this.theme === 'on-dark') {
          classes.push('bg-transparent', 'text-white');
          if (!this.isDisabled) {
            classes.push('group-hover:bg-gray-100');
          }
        } else {
          classes.push('bg-transparent', 'text-black');
          if (!this.isDisabled) {
            classes.push(
              'group-hover:bg-gray-100',
              'group-hover:border-gray-300',
              'group-hover:text-black',
              'group-focus:bg-black',
              'group-focus:text-white',
              'group-active:bg-black',
              'group-active:text-white'
            );
          }
        }

        break;

      case 'link':
        if (this.theme === 'on-dark') {
          classes.push('text-white');
          if (!this.isDisabled) {
            classes.push('group-hover:border-white');
          }
        }
    }

    return classes;
  }

  public getContentClasses(): string[] {
    const classes = [];

    if (this.iconPos === 'right') {
      classes.push('flex-row-reverse');
    }

    if (this.size === 'S') {
      classes.push('text-sm');
    }

    return classes;
  }

  public getIconClasses(): string[] {
    const classes = [];

    if (this.iconPos === 'right') {
      classes.push('pl-1.5');
    } else {
      classes.push('pr-1.5');
    }
    return classes;
  }

  public isIconContainerVisible(): boolean {
    return !!this.icon || this.loading;
  }
}
