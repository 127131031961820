import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { InfoDialogModule } from './dialog-info';
import { FeedbackDialogModule } from './feedback-dialog';
import { CompanyFilterDialogModule } from './company-filter-dialog';

@NgModule({
  imports: [
    CommonModule,
    InfoDialogModule,
    FeedbackDialogModule,
    CompanyFilterDialogModule,
  ],
})
export class DialogAllModule {}
