import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { WkwFormFieldExtComponent } from '@wkw-form';
import { Subscription } from 'rxjs';

@Component({
  selector: 'wkw-toggle',
  templateUrl: './toggle.component.html',
  styleUrls: ['./toggle.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ToggleComponent
  extends WkwFormFieldExtComponent
  implements OnInit, OnDestroy
{
  @Input() innerLabel?: string;
  @Input() isDisabled = false;

  public isChecked = false;

  private subscriptions = new Subscription();

  constructor(private cdRef: ChangeDetectorRef) {
    super();
  }

  ngOnInit(): void {
    this.listenForFormControlChanges();
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  private listenForFormControlChanges(): void {
    this.isChecked = this.formControl?.value;
    this.subscriptions.add(
      this.formControl?.valueChanges.subscribe((value) => {
        this.isChecked = value;
        this.cdRef.markForCheck();
      })
    );
  }

  public onChange(): void {
    this.formControl?.patchValue(!this.isChecked);
    this.formControl?.markAsDirty();
    this.formControl?.markAsTouched();
  }

  public getLabelClasses(): string[] {
    const classes = [];

    if (this.isDisabled) {
      classes.push('text-gray-500');
    }

    return classes;
  }
}
