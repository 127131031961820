import { WkwEnvironment } from '@wkw-types';

export const WKW_ENVIRONMENT: WkwEnvironment = {
  production: true,
  env: 'prod',
  firebaseConfig: {
    apiKey: 'AIzaSyBKsF-NsMpVHsc3NJdW6uhvlCSJ8oZKibY',
    authDomain: 'wkw-ki.firebaseapp.com',
    projectId: 'wkw-ki',
    storageBucket: 'wkw-ki.appspot.com',
    messagingSenderId: '552338135954',
    appId: '1:552338135954:web:062a256a32cf4a75637f4b',
  },
  api: {
    baseUrl: 'https://chatbot-auth.wkw.at/',
    authUrl: 'https://chatbot-auth.wkw.at/',
  },
};
