import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'wkw-dialog',
  templateUrl: './dialog.component.html',
  styleUrls: ['./dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DialogComponent implements OnInit {
  @Input() isFooterVisible = false;

  constructor(private dialogRef: MatDialog) {}

  ngOnInit(): void {}

  public onCloseClick(): void {
    this.dialogRef.closeAll();
  }
}
