import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit,
} from '@angular/core';
import { neverError } from '@wkw-helpers';
import { WkwIcon, WkwIconSize, WkwNotificationType } from '@wkw-types';

@Component({
  selector: 'wkw-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NotificationComponent implements OnInit {
  @Input({ required: true }) type!: WkwNotificationType;
  @Input() icon?: WkwIcon;
  @Input() hasIcon = true;
  @Input() iconSize: WkwIconSize = 24;

  constructor() {}

  ngOnInit(): void {
    if (!this.icon) {
      this.icon = this.getDefaultIcon();
    }
  }

  private getDefaultIcon(): WkwIcon {
    switch (this.type) {
      case 'info':
        return 'info-16';
      default:
        throw neverError(this.type);
    }
  }

  public getInfoClasses(): string[] {
    const classes: string[] = [];

    switch (this.type) {
      case 'info':
        classes.push('bg-gray-100', 'text-gray-500', 'text-sm');

        break;
    }

    return classes;
  }
}
