import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ButtonModule } from '@wkw-ui/button';
import { DialogModule } from '@wkw-ui/dialog';
import { FormModule } from '@wkw-form';
import { TranslateModule } from '@ngx-translate/core';
import { FeedbackDialogComponent } from './feedback-dialog.component';

@NgModule({
  imports: [
    CommonModule,
    ButtonModule,
    DialogModule,
    FormModule,
    TranslateModule,
  ],
  declarations: [FeedbackDialogComponent],
})
export class FeedbackDialogModule {}
