import { Component, Input } from '@angular/core';
import { FormControl, ValidationErrors } from '@angular/forms';
import { getUniqueId } from '@wkw-helpers';

@Component({
  template: '',
})
export abstract class WkwFormFieldExtComponent {
  @Input() formControl?: FormControl;

  @Input() htmlId = getUniqueId();

  protected isTouched(): boolean {
    return !!this.formControl?.touched;
  }

  protected getErrors(): ValidationErrors | undefined {
    if (this.formControl?.errors) {
      return this.formControl.errors;
    }

    return undefined;
  }

  protected shouldShowErrors(): boolean {
    return this.isTouched() && !!this.getErrors();
  }
}
