import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { debounceTime, Subscription } from 'rxjs';
import { getUniqueId } from '@wkw-helpers';
import {
  getFloatAsFormattedString,
  WkwFormFieldExtComponent,
  WkwFormFieldTextType,
  WkwInputValue,
} from '@wkw-form';

@Component({
  selector: 'wkw-rating',
  templateUrl: './rating.component.html',
  styleUrls: ['./rating.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RatingComponent
  extends WkwFormFieldExtComponent
  implements OnInit, OnDestroy
{
  @Input() isDisabled = false;
  @Input() type: WkwFormFieldTextType = 'number';
  @Input() rating: number = 0;
  @Output() ratingChange: EventEmitter<number> = new EventEmitter<number>();
  @ViewChild('input', { static: true })
  public outputType: WkwFormFieldTextType = 'number';
  public value?: WkwInputValue;
  public stars: number[] = [1, 2, 3, 4, 5];
  public filledStars: number = 0;
  private subscriptions = new Subscription();
  private inputElement!: ElementRef<HTMLInputElement>;

  constructor(private cdRef: ChangeDetectorRef) {
    super();
    this.htmlId = getUniqueId();
  }

  ngOnInit(): void {
    this.value = this.rating;
    this.listenForFormControlChanges();
    this.listenForStatusChanges();
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  private listenForStatusChanges(): void {
    this.subscriptions.add(
      this.formControl?.statusChanges.pipe(debounceTime(50)).subscribe(() => {
        setTimeout(() => {
          this.cdRef.detectChanges();
        });
      })
    );
  }

  private listenForFormControlChanges(): void {
    this.subscriptions.add(
      this.formControl?.valueChanges.subscribe((value) => {
        this.setValue(value);
        this.cdRef.detectChanges();
      })
    );

    this.setValue(this.formControl?.value);
  }

  private setValue(inputValue: WkwInputValue): void {
    if (this.type === 'number' && typeof inputValue === 'number') {
      this.value = getFloatAsFormattedString(inputValue);
    } else {
      this.value = inputValue;
    }
  }

  public fillStars(starCount: number): void {
    this.filledStars = starCount;
  }

  public resetStars(): void {
    this.filledStars = this.rating;
  }

  public setRating(rating: number): void {
    this.rating = rating;
    this.ratingChange.emit(rating);

    if (this.inputElement) {
      this.inputElement.nativeElement.value = rating.toString();
    }

    this.formControl?.setValue(rating);
    this.formControl?.markAsTouched();
    this.formControl?.markAsDirty();
  }
}
