import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RatingComponent } from './rating.component';
import { IconModule } from '@wkw-ui/icon';
import { FormsModule } from '@angular/forms';

@NgModule({
  imports: [CommonModule, IconModule, FormsModule],
  exports: [RatingComponent],
  declarations: [RatingComponent],
})
export class RatingModule {}
