import { Injectable } from '@angular/core';
import { WkwCompanyInfo } from '@wkw-types';
import { BehaviorSubject, filter } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class StoreService {
  private company$$ = new BehaviorSubject<WkwCompanyInfo | undefined>(
    undefined
  );

  public company$ = this.company$$
    .asObservable()
    .pipe(
      filter((company): company is WkwCompanyInfo => company !== undefined)
    );

  public setCompany(company: WkwCompanyInfo): void {
    this.company$$.next(company);
  }
}
