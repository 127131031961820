export function scrollToError(formContainer: HTMLElement): void {
  const errorElement = formContainer.querySelector('.js-error-scroll');
  if (errorElement) {
    errorElement.scrollIntoView({
      behavior: 'smooth',
      block: 'center',
    });
  }
}

export function getFloatAsFormattedString(value: number | string): string {
  const strVal: string = typeof value === 'string' ? value : value.toString();
  if (isNaN(parseFloat(strVal))) {
    return '';
  }
  return strVal.replace('.', ',');
}

export function getLabelFromKey(formField: string, key: string): string {
  switch (formField) {
    case 'sector':
      switch (key) {
        case 'commerce-sector':
          return 'company-filter-dialog.sector.options.commerce';
        case 'industry-sector':
          return 'company-filter-dialog.sector.options.industry';
        case 'trade-sector':
          return 'company-filter-dialog.sector.options.trade';
        case 'banking-sector':
          return 'company-filter-dialog.sector.options.banking';
        case 'traffic-sector':
          return 'company-filter-dialog.sector.options.traffic';
        case 'tourism-sector':
          return 'company-filter-dialog.sector.options.tourism';
        case 'information-sector':
          return 'company-filter-dialog.sector.options.information';
        default:
          return '';
      }
    case 'commerce-sector':
      switch (key) {
        case 'construction':
          return 'company-filter-dialog.commerce-sector.options.construction';
        case 'roofing':
          return 'company-filter-dialog.commerce-sector.options.roofing';
        case 'ceramists':
          return 'company-filter-dialog.commerce-sector.options.tile-layers-ceramists';
        case 'painting':
          return 'company-filter-dialog.commerce-sector.options.painting';
        case 'construction-auxiliary':
          return 'company-filter-dialog.commerce-sector.options.construction-auxiliary';
        case 'wood-construction':
          return 'company-filter-dialog.commerce-sector.options.wood-construction';
        case 'wood-workers':
          return 'company-filter-dialog.commerce-sector.options.wood-workers';
        case 'metal':
          return 'company-filter-dialog.commerce-sector.options.metal-technician';
        case 'sanitary':
          return 'company-filter-dialog.commerce-sector.options.sanitary';
        case 'electrical':
          return 'company-filter-dialog.commerce-sector.options.electrical-technicians';
        case 'plastics':
          return 'company-filter-dialog.commerce-sector.options.plastics-processors';
        case 'mechatronics':
          return 'company-filter-dialog.commerce-sector.options.mechatronics-engineer';
        case 'automotive':
          return 'company-filter-dialog.commerce-sector.options.automotive-engineering';
        case 'crafts':
          return 'company-filter-dialog.commerce-sector.options.crafts';
        case 'fashion':
          return 'company-filter-dialog.commerce-sector.options.fashion';
        case 'healthcare':
          return 'company-filter-dialog.commerce-sector.options.healthcare';
        case 'food-industry':
          return 'company-filter-dialog.commerce-sector.options.food-industry';
        case 'cosmeticians':
          return 'company-filter-dialog.commerce-sector.options.cosmeticians';
        case 'gardeners':
          return 'company-filter-dialog.commerce-sector.options.gardeners';
        case 'photography':
          return 'company-filter-dialog.commerce-sector.options.photography';
        case 'chemical':
          return 'company-filter-dialog.commerce-sector.options.chemical-industry';
        case 'hairdressers':
          return 'company-filter-dialog.commerce-sector.options.hairdressers';
        case 'chimney-sweep':
          return 'company-filter-dialog.commerce-sector.options.chimney-sweep';
        case 'commercial':
          return 'company-filter-dialog.commerce-sector.options.commercial-service-provider';
        case 'personal-care':
          return 'company-filter-dialog.commerce-sector.options.personal-care';
        case 'service':
          return 'company-filter-dialog.commerce-sector.options.personal-service-provider';
        case 'film':
          return 'company-filter-dialog.commerce-sector.options.film-industry';
        default:
          return '';
      }
    case 'trade-sector':
      switch (key) {
        case 'food-trade':
          return 'company-filter-dialog.trade-sector.options.food-trade';
        case 'tobacconists':
          return 'company-filter-dialog.trade-sector.options.tobacconists';
        case 'pharmaceuticals':
          return 'company-filter-dialog.trade-sector.options.pharmaceuticals';
        case 'agricultural':
          return 'company-filter-dialog.trade-sector.options.agricultural-trade';
        case 'energy':
          return 'company-filter-dialog.trade-sector.options.energy-trading';
        case 'market-trade':
          return 'company-filter-dialog.trade-sector.options.market-trade';
        case 'international-trade':
          return 'company-filter-dialog.trade-sector.options.international-trade';
        case 'fashion-trade':
          return 'company-filter-dialog.trade-sector.options.fashion-trade';
        case 'sales':
          return 'company-filter-dialog.trade-sector.options.direct-sales';
        case 'paper-trade':
          return 'company-filter-dialog.trade-sector.options.paper-toy-trade';
        case 'commercials-agents':
          return 'company-filter-dialog.trade-sector.options.commercials-agents';
        case 'antiques':
          return 'company-filter-dialog.trade-sector.options.antiques-trade';
        case 'wood-trade':
          return 'company-filter-dialog.trade-sector.options.wood-trade';
        case 'technology-trade':
          return 'company-filter-dialog.trade-sector.options.technology-trade';
        case 'vehicle-trade':
          return 'company-filter-dialog.trade-sector.options.vehicle-trade';
        case 'medical-products':
          return 'company-filter-dialog.trade-sector.options.medical-products-trade';
        case 'electrical-retail':
          return 'company-filter-dialog.trade-sector.options.electrical-retail';
        case 'internet-trade':
          return 'company-filter-dialog.trade-sector.options.internet-trade';
        case 'insurance-agents':
          return 'company-filter-dialog.trade-sector.options.insurance-agents';
        default:
          return '';
      }
    case 'traffic-sector':
      switch (key) {
        case 'railroads':
          return 'company-filter-dialog.traffic-sector.options.railroads';
        case 'shipping-companies':
          return 'company-filter-dialog.traffic-sector.options.bus-shipping-companies';
        case 'ropeways':
          return 'company-filter-dialog.traffic-sector.options.ropeways';
        case 'logistics':
          return 'company-filter-dialog.traffic-sector.options.logistics';
        case 'transport-industry':
          return 'company-filter-dialog.traffic-sector.options.transport-industry';
        case 'freight-transport':
          return 'company-filter-dialog.traffic-sector.options.freight-transport';
        case 'general-traffic':
          return 'company-filter-dialog.traffic-sector.options.general-traffic';
        case 'service-companies':
          return 'company-filter-dialog.traffic-sector.options.service-companies';
        default:
          return '';
      }
    case 'tourism-sector':
      switch (key) {
        case 'gastronomy':
          return 'company-filter-dialog.tourism-sector.options.gastronomy';
        case 'hotel':
          return 'company-filter-dialog.tourism-sector.options.hotel-industry';
        case 'health-companies':
          return 'company-filter-dialog.tourism-sector.options.health-companies';
        case 'travel-agencies':
          return 'company-filter-dialog.tourism-sector.options.travel-agencies';
        case 'entertainment':
          return 'company-filter-dialog.tourism-sector.options.entertainment';
        case 'sport-facilities':
          return 'company-filter-dialog.tourism-sector.options.sport-facilities';
        default:
          return '';
      }
    case 'information-sector':
      switch (key) {
        case 'resource-management':
          return 'company-filter-dialog.information-sector.options.resource-management';
        case 'financial-service':
          return 'company-filter-dialog.information-sector.options.financial-service-provider';
        case 'advertising':
          return 'company-filter-dialog.information-sector.options.advertising';
        case 'accounting':
          return 'company-filter-dialog.information-sector.options.accounting';
        case 'engineering-offices':
          return 'company-filter-dialog.information-sector.options.engineering-offices';
        case 'real-estate':
          return 'company-filter-dialog.information-sector.options.rreal-estate';
        case 'media-industry':
          return 'company-filter-dialog.information-sector.options.media-industry';
        case 'insurance-consultant':
          return 'company-filter-dialog.information-sector.options.insurance-consultant';
        case 'telecommunications':
          return 'company-filter-dialog.information-sector.options.telecommunications';
        default:
          return '';
      }
    case 'company-size':
      switch (key) {
        case 'one-person':
          return 'company-filter-dialog.company-size.options.one-person';
        case 'micro':
          return 'company-filter-dialog.company-size.options.micro-company';
        case 'small':
          return 'company-filter-dialog.company-size.options.small-company';
        case 'medium':
          return 'company-filter-dialog.company-size.options.medium-company';
        case 'large':
          return 'company-filter-dialog.company-size.options.large-company';
        default:
          return '';
      }
    default:
      return '';
  }
}
