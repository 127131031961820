<div>
  <div class="relative">
    <button [id]="htmlId" type="button" class="form-select" aria-haspopup="listbox" [attr.aria-expanded]="isDropdownOpen" (change)="onChange(select.value)" (click)="toggleDropdown()" [disabled]="isDisabled" (blur)="onBlur()" [ngClass]="getClasses()" #select>
      <span class="block truncate" *ngIf="selectedOption">{{ selectedOption.label | translate }}</span>
    </button>

    <ul *ngIf="isDropdownOpen" class="absolute shadow-base z-10 mt-1 max-h-44 w-full overflow-auto rounded bg-white py-1" tabindex="-1" role="listbox">
      <li *ngFor="let option of options; let i = index; trackBy: trackById" class="relative cursor-pointer py-3 px-4 hover:bg-gray-100" [attr.id]="'listbox-option-' + i" role="option" (click)="selectOption(option)">
        <span [ngClass]="{ 'font-medium': option === selectedOption }" class="block truncate">{{ option.label | translate }}</span>
      </li>
    </ul>
  </div>
</div>
