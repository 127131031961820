<wkw-dialog>
  <ng-container id="header">
    <div class="flex">
      <span class="typo-h4 font-bold">{{ 'feedback-dialog.title' | translate }}</span>
    </div>
  </ng-container>

  <div class="space-y-4 my-12">
    <wkw-form-field [formControl]="formGroup.controls.ratingLanguage" formControlName="ratingLanguage" [formField]="formFieldRatingLanguage"></wkw-form-field>
    <wkw-form-field [formControl]="formGroup.controls.ratingContent" [formField]="formFieldRatingContent"></wkw-form-field>
    <wkw-form-field [formControl]="formGroup.controls.feedback" [formField]="formFieldFeedback"></wkw-form-field>
  </div>

  <div class="flex justify-center md:justify-end space-x-3">
    <button wkw-button type="button" variant="outline" (click)="onCancelClick()">{{ 'actions.cancel' | translate }}</button>
    <button wkw-button type="button" (click)="onSubmitClick()">{{ 'actions.send' | translate }}</button>
  </div>
</wkw-dialog>
