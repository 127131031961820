import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnDestroy,
  OnInit,
} from '@angular/core';
import {
  getFloatAsFormattedString,
  WkwFormFieldExtComponent,
  WkwFormFieldTextType,
  WkwInputValue,
} from '@wkw-form';
import { debounceTime, Subscription } from 'rxjs';

@Component({
  selector: 'wkw-input',
  templateUrl: './input.component.html',
  styleUrls: ['./input.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InputComponent
  extends WkwFormFieldExtComponent
  implements OnInit, OnDestroy
{
  @Input() isDisabled = false;
  @Input() placeholder = '';
  @Input() autocomplete = '';
  @Input() type: WkwFormFieldTextType = 'text';

  public value?: WkwInputValue;
  public outputType: WkwFormFieldTextType = 'text';

  private subscriptions = new Subscription();

  constructor(private cdRef: ChangeDetectorRef) {
    super();
  }

  ngOnInit(): void {
    if (this.type === 'number') {
      this.outputType = 'text';
    } else {
      this.outputType = this.type ?? 'text';
    }

    this.listenForFormControlChanges();
    this.listenForStatusChanges();
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  private listenForFormControlChanges(): void {
    this.subscriptions.add(
      this.formControl?.valueChanges.subscribe((value) => {
        this.setValue(value);
        this.cdRef.detectChanges();
      })
    );

    this.setValue(this.formControl?.value);
  }

  private setValue(inputValue: WkwInputValue): void {
    if (this.type === 'number' && typeof inputValue === 'number') {
      this.value = getFloatAsFormattedString(inputValue);
    } else {
      this.value = inputValue;
    }
  }

  private listenForStatusChanges(): void {
    this.subscriptions.add(
      this.formControl?.statusChanges.pipe(debounceTime(50)).subscribe(() => {
        setTimeout(() => {
          this.cdRef.detectChanges();
        });
      })
    );
  }

  public onChange(value: WkwInputValue): void {
    let newValue: WkwInputValue = value ?? '';

    if (this.type === 'number') {
      if (newValue !== '-') {
        newValue =
          typeof newValue === 'string' ? newValue.replace(/,/g, '.') : newValue;
        newValue = parseFloat(newValue.toString());
        if (isNaN(newValue)) {
          newValue = '';
        }
      }
    }

    if (this.type === 'date') {
      if (newValue !== '') {
        newValue = new Date(newValue);
        if (isNaN(newValue.getTime())) {
          newValue = undefined;
        }
      } else {
        newValue = undefined;
      }
    }

    this.formControl?.patchValue(newValue);
    this.formControl?.markAsTouched();
    this.formControl?.markAsDirty();
  }

  public onBlur(): void {
    this.formControl?.markAsTouched();
  }

  public getClasses(): string[] {
    const classes = [];
    if (this.shouldShowErrors()) {
      classes.push('form-error');
    }
    return classes;
  }

  public getValue(): WkwInputValue | undefined {
    if (this.type === 'date') {
      if (this.value) {
        // convert date to format y-MM-dd
        const date = new Date(this.value);
        const year = date.getFullYear();
        const month = date.getMonth() + 1;
        const day = date.getDate();
        return `${year}-${month < 10 ? '0' + month : month}-${
          day < 10 ? '0' + day : day
        }`;
      }
    }

    return this.value;
  }
}
