import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ButtonModule } from '@wkw-ui/button';
import { CheckboxModule } from './checkbox';
import { FormFieldModule } from './form-field';
import { RatingModule } from './rating';
import { SelectModule } from './select';

@NgModule({
  declarations: [],
  imports: [CommonModule, FormFieldModule, ButtonModule],
  exports: [FormFieldModule, SelectModule, CheckboxModule, RatingModule],
})
export class FormModule {}
