/* 🤖 this file was generated by svg-to-ts */
export const iconAdjustments20: {
  name: 'adjustments-20';
  data: string;
} = {
  name: 'adjustments-20',
  data: `<svg width="20" height="20" xmlns="http://www.w3.org/2000/svg"><path d="M3.527 6.86a2.083 2.083 0 1 1 2.946 2.946A2.083 2.083 0 0 1 3.527 6.86ZM5 7.083a1.25 1.25 0 1 0 0 2.5 1.25 1.25 0 0 0 0-2.5Z"/><path d="M5 2.917c.23 0 .417.186.417.416v3.334a.417.417 0 0 1-.834 0V3.333c0-.23.187-.416.417-.416Zm0 6.666c.23 0 .417.187.417.417v6.667a.417.417 0 0 1-.834 0V10c0-.23.187-.417.417-.417Zm3.527 2.277a2.083 2.083 0 1 1 2.946 2.946 2.083 2.083 0 0 1-2.946-2.946Zm1.473.223a1.25 1.25 0 1 0 0 2.5 1.25 1.25 0 0 0 0-2.5Z"/><path d="M10 2.917c.23 0 .417.186.417.416v8.334a.417.417 0 1 1-.834 0V3.333c0-.23.187-.416.417-.416Zm0 11.666c.23 0 .417.187.417.417v1.667a.417.417 0 1 1-.834 0V15c0-.23.187-.417.417-.417ZM13.527 4.36a2.083 2.083 0 1 1 2.946 2.947 2.083 2.083 0 0 1-2.946-2.947ZM15 4.583a1.25 1.25 0 1 0 0 2.5 1.25 1.25 0 0 0 0-2.5Z"/><path d="M15 2.917c.23 0 .417.186.417.416v.834a.417.417 0 0 1-.834 0v-.834c0-.23.187-.416.417-.416Zm0 4.166c.23 0 .417.187.417.417v9.167a.417.417 0 0 1-.834 0V7.5c0-.23.187-.417.417-.417Z"/></svg>`
};
export const iconAlertTriangle20: {
  name: 'alert-triangle-20';
  data: string;
} = {
  name: 'alert-triangle-20',
  data: `<svg height="21" width="20" xmlns="http://www.w3.org/2000/svg"><path d="M10 7.625a.5.5 0 0 1 .5.5v3.333a.5.5 0 1 1-1 0V8.125a.5.5 0 0 1 .5-.5zm0 6.667a.5.5 0 0 0 0 1h.008a.5.5 0 1 0 0-1z"/><path d="M8.892 2.903a2.157 2.157 0 0 1 2.902.76l.002.003 7.023 11.723a2.157 2.157 0 0 1-1.844 3.236H2.926a2.157 2.157 0 0 1-1.844-3.236l.004-.007 7.02-11.719c.193-.317.463-.579.786-.76zm1.058.722a1.157 1.157 0 0 0-.988.556l-.001.001-7.015 11.71a1.157 1.157 0 0 0 .989 1.733h14.031a1.157 1.157 0 0 0 .989-1.733L10.94 4.182l-.001-.001a1.157 1.157 0 0 0-.989-.556z"/></svg>`
};
export const iconArrowDown16: {
  name: 'arrow-down-16';
  data: string;
} = {
  name: 'arrow-down-16',
  data: `<svg width="16" height="16" xmlns="http://www.w3.org/2000/svg"><path d="M2.97 5.22a.75.75 0 0 1 1.06 0L8 9.19l3.97-3.97a.75.75 0 1 1 1.06 1.06l-4.5 4.5a.75.75 0 0 1-1.06 0l-4.5-4.5a.75.75 0 0 1 0-1.06Z"/></svg>`
};
export const iconArrowDown24: {
  name: 'arrow-down-24';
  data: string;
} = {
  name: 'arrow-down-24',
  data: `<svg width="24" height="24" xmlns="http://www.w3.org/2000/svg"><path d="m11.47 16.281-7.5-7.5A.75.75 0 0 1 5.03 7.72L12 14.69l6.969-6.97a.75.75 0 1 1 1.061 1.06l-7.5 7.5a.747.747 0 0 1-1.061 0Z"/></svg>`
};
export const iconCheck16: {
  name: 'check-16';
  data: string;
} = {
  name: 'check-16',
  data: `<svg width="16" height="16" xmlns="http://www.w3.org/2000/svg"><path d="M5.631 11.39 2.55 8.28 1.5 9.331 5.631 13.5 14.5 4.551 13.458 3.5 5.63 11.39Z"/></svg>`
};
export const iconCheck24: {
  name: 'check-24';
  data: string;
} = {
  name: 'check-24',
  data: `<svg width="24" height="24" xmlns="http://www.w3.org/2000/svg"><path d="m21.53 7.28-12 12a.75.75 0 0 1-1.06 0l-5.25-5.25a.75.75 0 1 1 1.06-1.06L9 17.69 20.47 6.22a.75.75 0 1 1 1.06 1.06Z"/></svg>`
};
export const iconCheckbox20: {
  name: 'checkbox-20';
  data: string;
} = {
  name: 'checkbox-20',
  data: `<svg width="20" height="20" xmlns="http://www.w3.org/2000/svg"><path d="M17.02 4.646a.5.5 0 0 1 0 .708l-6.666 6.666a.5.5 0 0 1-.708 0l-2.5-2.5a.5.5 0 1 1 .708-.707L10 10.96l6.313-6.314a.5.5 0 0 1 .707 0Z"/><path d="M5 3.833A1.167 1.167 0 0 0 3.833 5v10A1.167 1.167 0 0 0 5 16.167h10A1.167 1.167 0 0 0 16.167 15v-5a.5.5 0 0 1 1 0v5A2.167 2.167 0 0 1 15 17.167H5A2.167 2.167 0 0 1 2.833 15V5A2.167 2.167 0 0 1 5 2.833h7.5a.5.5 0 1 1 0 1H5Z"/></svg>`
};
export const iconCheckbox24: {
  name: 'checkbox-24';
  data: string;
} = {
  name: 'checkbox-24',
  data: `<svg width="24" height="24" xmlns="http://www.w3.org/2000/svg"><path d="M20.389 5.611a.55.55 0 0 1 0 .778l-8 8a.55.55 0 0 1-.778 0l-3-3a.55.55 0 0 1 .778-.778L12 13.222l7.611-7.611a.55.55 0 0 1 .778 0Z"/><path d="M6 4.55A1.45 1.45 0 0 0 4.55 6v12A1.45 1.45 0 0 0 6 19.45h12A1.45 1.45 0 0 0 19.45 18v-6a.55.55 0 1 1 1.1 0v6A2.55 2.55 0 0 1 18 20.55H6A2.55 2.55 0 0 1 3.45 18V6A2.55 2.55 0 0 1 6 3.45h9a.55.55 0 0 1 0 1.1H6Z"/></svg>`
};
export const iconChevronDown16: {
  name: 'chevron-down-16';
  data: string;
} = {
  name: 'chevron-down-16',
  data: `<svg width="16" height="16" xmlns="http://www.w3.org/2000/svg"><path d="M13.033 5.967a.4.4 0 0 1 0 .566l-4.5 4.5a.4.4 0 0 1-.566 0l-4.5-4.5a.4.4 0 0 1 .566-.566l4.217 4.217 4.217-4.217a.4.4 0 0 1 .566 0Z"/></svg>`
};
export const iconChevronRight12: {
  name: 'chevron-right-12';
  data: string;
} = {
  name: 'chevron-right-12',
  data: `<svg width="12" height="13" xmlns="http://www.w3.org/2000/svg"><path d="M4.788 3.788a.3.3 0 0 1 .424 0l3 3a.3.3 0 0 1 0 .424l-3 3a.3.3 0 0 1-.424-.424L7.576 7 4.788 4.212a.3.3 0 0 1 0-.424Z"/></svg>`
};
export const iconChevronRight16: {
  name: 'chevron-right-16';
  data: string;
} = {
  name: 'chevron-right-16',
  data: `<svg width="16" height="16" xmlns="http://www.w3.org/2000/svg"><path d="M5.717 3.717a.4.4 0 0 1 .566 0l4.5 4.5a.4.4 0 0 1 0 .566l-4.5 4.5a.4.4 0 0 1-.566-.566L9.934 8.5 5.717 4.283a.4.4 0 0 1 0-.566Z"/></svg>`
};
export const iconChevronRight20: {
  name: 'chevron-right-20';
  data: string;
} = {
  name: 'chevron-right-20',
  data: `<svg width="20" height="20" xmlns="http://www.w3.org/2000/svg"><path d="M5.646 2.646a.5.5 0 0 1 .708 0l7 7a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708-.708L12.293 10 5.646 3.354a.5.5 0 0 1 0-.708Z"/></svg>`
};
export const iconClose16: {
  name: 'close-16';
  data: string;
} = {
  name: 'close-16',
  data: `<svg width="16" height="16" xmlns="http://www.w3.org/2000/svg"><path d="M13.03 11.97a.751.751 0 1 1-1.062 1.062L8 9.062l-3.97 3.969a.751.751 0 1 1-1.062-1.063L6.938 8 2.969 4.03a.751.751 0 1 1 1.063-1.062L8 6.938l3.97-3.97a.751.751 0 1 1 1.062 1.062L9.062 8l3.969 3.97Z"/></svg>`
};
export const iconClose20: {
  name: 'close-20';
  data: string;
} = {
  name: 'close-20',
  data: `<svg width="20" height="20" xmlns="http://www.w3.org/2000/svg"><path d="M15.979 4.021a.5.5 0 0 1 0 .708l-11.25 11.25a.5.5 0 0 1-.708-.708l11.25-11.25a.5.5 0 0 1 .708 0Z"/><path d="M4.021 4.021a.5.5 0 0 1 .708 0l11.25 11.25a.5.5 0 0 1-.708.708L4.021 4.729a.5.5 0 0 1 0-.708Z"/></svg>`
};
export const iconClose24: {
  name: 'close-24';
  data: string;
} = {
  name: 'close-24',
  data: `<svg width="24" height="24" xmlns="http://www.w3.org/2000/svg"><path d="M19.28 18.22a.75.75 0 1 1-1.06 1.06L12 13.06l-6.22 6.22a.75.75 0 0 1-1.06-1.06L10.94 12 4.72 5.78a.75.75 0 1 1 1.06-1.06L12 10.94l6.22-6.22a.75.75 0 1 1 1.06 1.06L13.06 12l6.22 6.22Z"/></svg>`
};
export const iconClose32: {
  name: 'close-32';
  data: string;
} = {
  name: 'close-32',
  data: `<svg width="32" height="32" xmlns="http://www.w3.org/2000/svg"><path d="M25.424 6.576a.6.6 0 0 1 0 .848l-18 18a.6.6 0 0 1-.848-.848l18-18a.6.6 0 0 1 .848 0Z"/><path d="M6.576 6.576a.6.6 0 0 1 .848 0l18 18a.6.6 0 0 1-.848.848l-18-18a.6.6 0 0 1 0-.848Z"/></svg>`
};
export const iconEdit24: {
  name: 'edit-24';
  data: string;
} = {
  name: 'edit-24',
  data: `<svg width="24" height="24" xmlns="http://www.w3.org/2000/svg"><path d="M14.111 5.111A3.378 3.378 0 1 1 18.89 9.89l-10.5 10.5a.55.55 0 0 1-.39.16H4a.55.55 0 0 1-.55-.55v-4a.55.55 0 0 1 .161-.389l10.5-10.5Zm2.39.11c-.605 0-1.185.24-1.612.668L4.55 16.228v3.222h3.222l10.34-10.339a2.278 2.278 0 0 0-1.612-3.89Z"/><path d="M13.111 6.111a.55.55 0 0 1 .778 0l4 4a.55.55 0 0 1-.778.778l-4-4a.55.55 0 0 1 0-.778Z"/></svg>`
};
export const iconFilter20: {
  name: 'filter-20';
  data: string;
} = {
  name: 'filter-20',
  data: `<svg width="20" height="20" xmlns="http://www.w3.org/2000/svg"><path d="M2.917 3.333c0-.23.186-.416.416-.416h13.334c.23 0 .416.186.416.416v1.81c0 .553-.22 1.083-.61 1.473l-3.556 3.556v5.661c0 .18-.115.339-.285.396l-5 1.666a.417.417 0 0 1-.549-.395v-6.922L3.458 6.59a2.083 2.083 0 0 1-.541-1.4V3.332Zm.833.417v1.44c0 .31.116.61.325.84l3.733 4.106c.07.077.109.177.109.28v6.506l4.166-1.389V10c0-.11.044-.217.123-.295l3.678-3.678a1.25 1.25 0 0 0 .366-.884V3.75H3.75Z"/></svg>`
};
export const iconIconCheck32: {
  name: 'icon-check-32';
  data: string;
} = {
  name: 'icon-check-32',
  data: `<svg width="32" height="32" xmlns="http://www.w3.org/2000/svg"><path d="M11.216 4.452a12.5 12.5 0 1 1 9.567 23.095 12.5 12.5 0 0 1-9.567-23.095ZM16 4.5a11.5 11.5 0 1 0 0 23 11.5 11.5 0 0 0 0-23Z"/><path d="M20.354 12.98a.5.5 0 0 1 0 .707L15.02 19.02a.5.5 0 0 1-.707 0l-2.667-2.666a.5.5 0 0 1 .708-.707l2.313 2.313 4.98-4.98a.5.5 0 0 1 .707 0Z"/></svg>`
};
export const iconInfo16: {
  name: 'info-16';
  data: string;
} = {
  name: 'info-16',
  data: `<svg width="16" height="16" xmlns="http://www.w3.org/2000/svg"><path d="M7.282 5.846h1.396V4.45H7.282m.698 9.074A5.591 5.591 0 0 1 2.396 7.94 5.591 5.591 0 0 1 7.98 2.356a5.591 5.591 0 0 1 5.584 5.584 5.592 5.592 0 0 1-5.584 5.584ZM7.98.96a6.98 6.98 0 1 0 0 13.96 6.98 6.98 0 0 0 0-13.96Zm-.698 10.47h1.396V7.242H7.282v4.188Z"/></svg>`
};
export const iconInfo20: {
  name: 'info-20';
  data: string;
} = {
  name: 'info-20',
  data: `<svg width="20" height="20" xmlns="http://www.w3.org/2000/svg"><path d="M10 3a7 7 0 1 0 0 14 7 7 0 0 0 0-14Zm-8 7a8 8 0 1 1 16 0 8 8 0 0 1-16 0Z"/><path d="M9.5 6.667a.5.5 0 0 1 .5-.5h.008a.5.5 0 1 1 0 1H10a.5.5 0 0 1-.5-.5ZM8.667 10a.5.5 0 0 1 .5-.5H10a.5.5 0 0 1 .5.5v2.833h.333a.5.5 0 0 1 0 1H10a.5.5 0 0 1-.5-.5V10.5h-.333a.5.5 0 0 1-.5-.5Z"/></svg>`
};
export const iconInfo24: {
  name: 'info-24';
  data: string;
} = {
  name: 'info-24',
  data: `<svg width="24" height="24" xmlns="http://www.w3.org/2000/svg"><path d="M12 3.55a8.45 8.45 0 1 0 0 16.9 8.45 8.45 0 0 0 0-16.9ZM2.45 12a9.55 9.55 0 1 1 19.1 0 9.55 9.55 0 0 1-19.1 0Z"/><path d="M11.45 8a.55.55 0 0 1 .55-.55h.01a.55.55 0 0 1 0 1.1H12a.55.55 0 0 1-.55-.55Zm-1 4a.55.55 0 0 1 .55-.55h1a.55.55 0 0 1 .55.55v3.45H13a.55.55 0 0 1 0 1.1h-1a.55.55 0 0 1-.55-.55v-3.45H11a.55.55 0 0 1-.55-.55Z"/></svg>`
};
export const iconInfo32: {
  name: 'info-32';
  data: string;
} = {
  name: 'info-32',
  data: `<svg width="32" height="32" xmlns="http://www.w3.org/2000/svg"><path d="M16 4.6C9.704 4.6 4.6 9.704 4.6 16S9.704 27.4 16 27.4 27.4 22.296 27.4 16 22.296 4.6 16 4.6ZM3.4 16C3.4 9.041 9.041 3.4 16 3.4S28.6 9.041 28.6 16 22.959 28.6 16 28.6 3.4 22.959 3.4 16Z"/><path d="M15.4 10.667a.6.6 0 0 1 .6-.6h.013a.6.6 0 0 1 0 1.2H16a.6.6 0 0 1-.6-.6ZM14.067 16a.6.6 0 0 1 .6-.6H16a.6.6 0 0 1 .6.6v4.733h.733a.6.6 0 1 1 0 1.2H16a.6.6 0 0 1-.6-.6V16.6h-.733a.6.6 0 0 1-.6-.6Z"/></svg>`
};
export const iconLightbulb24: {
  name: 'lightbulb-24';
  data: string;
} = {
  name: 'lightbulb-24',
  data: `<svg width="24" height="24" xmlns="http://www.w3.org/2000/svg"><path d="M12 2.45a.55.55 0 0 1 .55.55v1a.55.55 0 0 1-1.1 0V3a.55.55 0 0 1 .55-.55ZM5.211 5.211a.55.55 0 0 1 .778 0l.7.7a.55.55 0 1 1-.778.778l-.7-.7a.55.55 0 0 1 0-.778Zm13.578 0a.55.55 0 0 1 0 .778l-.7.7a.55.55 0 0 1-.778-.778l.7-.7a.55.55 0 0 1 .778 0ZM2.449 12A.55.55 0 0 1 3 11.45h1a.55.55 0 1 1 0 1.1H3a.55.55 0 0 1-.55-.55Zm17.001 0a.55.55 0 0 1 .55-.55h1a.55.55 0 0 1 0 1.1h-1a.55.55 0 0 1-.55-.55ZM12 7.55a4.45 4.45 0 0 0-2.67 8.01c.02.015.039.031.057.05a4.051 4.051 0 0 1 1.163 3.426 1.45 1.45 0 0 0 2.9 0 4.05 4.05 0 0 1 1.163-3.427.543.543 0 0 1 .057-.049A4.45 4.45 0 0 0 12 7.55Zm-3.245-.052a5.55 5.55 0 0 1 6.607 8.918 2.949 2.949 0 0 0-.818 2.503.544.544 0 0 1 .006.081 2.55 2.55 0 0 1-5.1 0 .55.55 0 0 1 .006-.08 2.95 2.95 0 0 0-.818-2.504 5.55 5.55 0 0 1 .117-8.918Z"/><path d="M9.15 17a.55.55 0 0 1 .55-.55h4.6a.55.55 0 1 1 0 1.1H9.7a.55.55 0 0 1-.55-.55Z"/></svg>`
};
export const iconListCheckbox20: {
  name: 'list-checkbox-20';
  data: string;
} = {
  name: 'list-checkbox-20',
  data: `<svg height="21" width="20" xmlns="http://www.w3.org/2000/svg"><path d="M4.175 4.8c.219-.219.515-.342.825-.342h7.5a.5.5 0 1 0 0-1H5a2.167 2.167 0 0 0-2.167 2.167v10A2.167 2.167 0 0 0 5 17.792h10a2.167 2.167 0 0 0 2.167-2.167v-5a.5.5 0 0 0-1 0v5A1.167 1.167 0 0 1 15 16.792H5a1.167 1.167 0 0 1-1.167-1.167v-10c0-.31.123-.606.342-.825zM17.02 5.979a.5.5 0 1 0-.707-.708L10 11.585 7.854 9.438a.5.5 0 0 0-.708.707l2.5 2.5a.5.5 0 0 0 .708 0z"/></svg>`
};
export const iconListCheckbox44: {
  name: 'list-checkbox-44';
  data: string;
} = {
  name: 'list-checkbox-44',
  data: `<svg height="44" width="44" xmlns="http://www.w3.org/2000/svg"><path d="M16.175 30.175c.219-.219.516-.342.825-.342h7.5a.5.5 0 1 0 0-1H17A2.167 2.167 0 0 0 14.833 31v10A2.167 2.167 0 0 0 17 43.167h10A2.167 2.167 0 0 0 29.167 41v-5a.5.5 0 0 0-1 0v5A1.167 1.167 0 0 1 27 42.167H17A1.167 1.167 0 0 1 15.833 41V31c0-.31.123-.606.342-.825z"/><path d="M29.02 31.354a.5.5 0 0 0-.707-.708L22 36.96l-2.146-2.147a.5.5 0 0 0-.708.707l2.5 2.5a.5.5 0 0 0 .708 0z"/></svg>`
};
export const iconListInfo20: {
  name: 'list-info-20';
  data: string;
} = {
  name: 'list-info-20',
  data: `<svg height="21" width="20" xmlns="http://www.w3.org/2000/svg"><path d="M10 3.625a7 7 0 1 0 0 14 7 7 0 0 0 0-14zm-8 7a8 8 0 1 1 16 0 8 8 0 0 1-16 0zm7.5-3.333a.5.5 0 0 1 .5-.5h.008a.5.5 0 1 1 0 1H10a.5.5 0 0 1-.5-.5zm-.333 2.833a.5.5 0 0 0 0 1H9.5v2.833a.5.5 0 0 0 .5.5h.833a.5.5 0 1 0 0-1H10.5v-2.833a.5.5 0 0 0-.5-.5z"/></svg>`
};
export const iconListInfo44: {
  name: 'list-info-44';
  data: string;
} = {
  name: 'list-info-44',
  data: `<svg height="44" width="44" xmlns="http://www.w3.org/2000/svg"><path d="M21.5 32.667a.5.5 0 0 1 .5-.5h.008a.5.5 0 1 1 0 1H22a.5.5 0 0 1-.5-.5zm-.333 2.833a.5.5 0 0 0 0 1h.333v2.833a.5.5 0 0 0 .5.5h.833a.5.5 0 1 0 0-1H22.5V36a.5.5 0 0 0-.5-.5z"/><path d="M14 36a8 8 0 1 1 16 0 8 8 0 0 1-16 0zm8-7a7 7 0 1 0 0 14 7 7 0 0 0 0-14z"/></svg>`
};
export const iconLogout24: {
  name: 'logout-24';
  data: string;
} = {
  name: 'logout-24',
  data: `<svg width="24" height="24" xmlns="http://www.w3.org/2000/svg"><path d="M5 3.25a2.5 2.5 0 0 0-2.5 2.5v12a2.5 2.5 0 0 0 2.5 2.5h7a2.5 2.5 0 0 0 2.5-2.5v-2a.5.5 0 1 0-1 0v2a1.5 1.5 0 0 1-1.5 1.5H5a1.5 1.5 0 0 1-1.5-1.5v-12A1.5 1.5 0 0 1 5 4.25h7a1.5 1.5 0 0 1 1.5 1.5v2a.5.5 0 0 0 1 0v-2a2.5 2.5 0 0 0-2.5-2.5H5Z"/><path d="M18.354 8.396a.5.5 0 1 0-.707.708l2.146 2.146H7a.5.5 0 0 0 0 1h12.793l-2.146 2.146a.5.5 0 0 0 .707.708l3-3a.5.5 0 0 0 0-.708l-3-3Z"/></svg>`
};
export const iconLogout32: {
  name: 'logout-32';
  data: string;
} = {
  name: 'logout-32',
  data: `<svg width="32" height="32" xmlns="http://www.w3.org/2000/svg"><path d="M4.357 5.357a3.267 3.267 0 0 1 2.31-.957H16a3.267 3.267 0 0 1 3.267 3.267v2.666a.6.6 0 0 1-1.2 0V7.667A2.067 2.067 0 0 0 16 5.6H6.667A2.067 2.067 0 0 0 4.6 7.667v16a2.066 2.066 0 0 0 2.067 2.066H16a2.067 2.067 0 0 0 2.067-2.066V21a.6.6 0 0 1 1.2 0v2.667A3.267 3.267 0 0 1 16 26.933H6.667A3.267 3.267 0 0 1 3.4 23.667v-16c0-.867.344-1.698.957-2.31Z"/><path d="M23.576 11.242a.6.6 0 0 1 .848 0l4 4a.6.6 0 0 1 0 .849l-4 4a.6.6 0 0 1-.848-.849l2.976-2.975H9.332a.6.6 0 0 1 0-1.2H26.55l-2.975-2.976a.6.6 0 0 1 0-.849Z"/></svg>`
};
export const iconMail16: {
  name: 'mail-16';
  data: string;
} = {
  name: 'mail-16',
  data: `<svg width="16" height="16" xmlns="http://www.w3.org/2000/svg"><path d="M3.333 4.4a.933.933 0 0 0-.933.933V12c0 .515.418.933.933.933h9.334A.933.933 0 0 0 13.6 12V5.333a.933.933 0 0 0-.933-.933H3.333ZM1.6 5.333c0-.957.776-1.733 1.733-1.733h9.334c.957 0 1.733.776 1.733 1.733V12c0 .957-.776 1.733-1.733 1.733H3.333A1.733 1.733 0 0 1 1.6 12V5.333Z"/><path d="M1.667 5.111a.4.4 0 0 1 .555-.11L8 8.852 13.778 5a.4.4 0 1 1 .444.666l-6 4a.4.4 0 0 1-.444 0l-6-4a.4.4 0 0 1-.11-.555Z"/></svg>`
};
export const iconMail20: {
  name: 'mail-20';
  data: string;
} = {
  name: 'mail-20',
  data: `<svg width="20" height="20" xmlns="http://www.w3.org/2000/svg"><path d="M4.167 5.5C3.522 5.5 3 6.022 3 6.667V15c0 .644.522 1.167 1.167 1.167h11.666c.645 0 1.167-.523 1.167-1.167V6.667c0-.645-.522-1.167-1.167-1.167H4.167ZM2 6.667C2 5.47 2.97 4.5 4.167 4.5h11.666C17.03 4.5 18 5.47 18 6.667V15c0 1.197-.97 2.167-2.167 2.167H4.167A2.167 2.167 0 0 1 2 15V6.667Z"/><path d="M2.084 6.39a.5.5 0 0 1 .693-.14L10 11.067l7.223-4.815a.5.5 0 0 1 .554.832l-7.5 5a.5.5 0 0 1-.554 0l-7.5-5a.5.5 0 0 1-.139-.694Z"/></svg>`
};
export const iconMenu20: {
  name: 'menu-20';
  data: string;
} = {
  name: 'menu-20',
  data: `<svg width="20" height="20" xmlns="http://www.w3.org/2000/svg"><path d="M2.917 5c0-.23.186-.417.416-.417h13.334a.417.417 0 0 1 0 .834H3.333A.417.417 0 0 1 2.917 5Zm0 5c0-.23.186-.417.416-.417h13.334a.417.417 0 0 1 0 .834H3.333A.417.417 0 0 1 2.917 10Zm0 5c0-.23.186-.417.416-.417h13.334a.417.417 0 1 1 0 .834H3.333A.417.417 0 0 1 2.917 15Z"/></svg>`
};
export const iconMenu32: {
  name: 'menu-32';
  data: string;
} = {
  name: 'menu-32',
  data: `<svg width="32" height="32" xmlns="http://www.w3.org/2000/svg"><path d="M4.833 8a.5.5 0 0 1 .5-.5h21.334a.5.5 0 1 1 0 1H5.333a.5.5 0 0 1-.5-.5Zm0 8a.5.5 0 0 1 .5-.5h21.334a.5.5 0 1 1 0 1H5.333a.5.5 0 0 1-.5-.5Zm.5 7.5a.5.5 0 0 0 0 1h21.334a.5.5 0 1 0 0-1H5.333Z"/></svg>`
};
export const iconPerson16: {
  name: 'person-16';
  data: string;
} = {
  name: 'person-16',
  data: `<svg width="16" height="16" xmlns="http://www.w3.org/2000/svg"><path d="M8 2.4a2.6 2.6 0 1 0 0 5.2 2.6 2.6 0 0 0 0-5.2ZM4.6 5a3.4 3.4 0 1 1 6.8 0 3.4 3.4 0 0 1-6.8 0Zm1.733 5.4A2.933 2.933 0 0 0 3.4 13.333V15a.4.4 0 0 1-.8 0v-1.667A3.734 3.734 0 0 1 6.333 9.6h3.334a3.733 3.733 0 0 1 3.733 3.733V15a.4.4 0 0 1-.8 0v-1.667A2.933 2.933 0 0 0 9.667 10.4H6.333Z"/></svg>`
};
export const iconPerson20: {
  name: 'person-20';
  data: string;
} = {
  name: 'person-20',
  data: `<svg width="20" height="20" xmlns="http://www.w3.org/2000/svg"><path d="M9.673 3.5a2.904 2.904 0 1 0 0 5.808 2.904 2.904 0 0 0 0-5.808ZM5.77 6.404a3.904 3.904 0 1 1 7.808 0 3.904 3.904 0 0 1-7.808 0Zm2.012 6.173A3.282 3.282 0 0 0 4.5 15.859v1.891a.5.5 0 0 1-1 0v-1.891a4.282 4.282 0 0 1 4.282-4.282h3.782a4.282 4.282 0 0 1 4.282 4.282v1.891a.5.5 0 0 1-1 0v-1.891a3.282 3.282 0 0 0-3.282-3.282H7.782Z"/></svg>`
};
export const iconPhone16: {
  name: 'phone-16';
  data: string;
} = {
  name: 'phone-16',
  data: `<svg width="16" height="16" xmlns="http://www.w3.org/2000/svg"><path d="M3.333 3.067a.933.933 0 0 0-.933.922 10.267 10.267 0 0 0 9.611 9.611.933.933 0 0 0 .922-.933V10.27l-2.765-1.106-.825 1.374a.4.4 0 0 1-.52.153 7.733 7.733 0 0 1-3.515-3.515.4.4 0 0 1 .153-.52l1.374-.825L5.73 3.067H3.333Zm-1.225-.293a1.733 1.733 0 0 1 1.225-.507H6a.4.4 0 0 1 .371.251l1.334 3.333a.4.4 0 0 1-.166.492l-1.342.805a6.934 6.934 0 0 0 2.655 2.655l.805-1.342a.4.4 0 0 1 .492-.166l3.333 1.334a.4.4 0 0 1 .251.371v2.667a1.733 1.733 0 0 1-1.757 1.732A11.066 11.066 0 0 1 1.6 4c0-.46.183-.9.508-1.226Z"/></svg>`
};
export const iconPhone20: {
  name: 'phone-20';
  data: string;
} = {
  name: 'phone-20',
  data: `<svg width="20" height="20" xmlns="http://www.w3.org/2000/svg"><path d="M4.167 3.833A1.167 1.167 0 0 0 3 4.986 12.833 12.833 0 0 0 15.014 17a1.167 1.167 0 0 0 1.153-1.167V12.84l-3.457-1.383-1.031 1.718a.5.5 0 0 1-.65.191 9.667 9.667 0 0 1-4.394-4.394.5.5 0 0 1 .191-.65l1.718-1.03-1.383-3.458H4.167Zm-1.532-.365a2.167 2.167 0 0 1 1.532-.635H7.5a.5.5 0 0 1 .464.315l1.667 4.166a.5.5 0 0 1-.207.615L7.746 8.936a8.666 8.666 0 0 0 3.319 3.318l1.006-1.678a.5.5 0 0 1 .615-.207l4.166 1.667a.5.5 0 0 1 .315.464v3.333A2.167 2.167 0 0 1 14.97 18 13.833 13.833 0 0 1 2 5c0-.575.228-1.126.635-1.532Z"/></svg>`
};
export const iconReload12: {
  name: 'reload-12';
  data: string;
} = {
  name: 'reload-12',
  data: `<svg width="12" height="13" xmlns="http://www.w3.org/2000/svg"><path d="M5.078 3.419c1.806-.464 3.672.466 4.36 2.193Zm-.149-.582c2.093-.537 4.264.54 5.066 2.553a.3.3 0 1 1-.557.222M4.929 2.837Zm.15.582A3.7 3.7 0 0 0 3.546 9.77 3.7 3.7 0 0 0 9.67 7.482a.3.3 0 1 1 .595.078 4.298 4.298 0 0 1-5.048 3.668A4.3 4.3 0 0 1 1.82 5.994a4.299 4.299 0 0 1 3.109-3.156"/><path d="M10 2.702a.3.3 0 0 1 .3.3v2.5a.3.3 0 0 1-.3.3H7.5a.3.3 0 1 1 0-.6h2.2v-2.2a.3.3 0 0 1 .3-.3Z"/></svg>`
};
export const iconReload16: {
  name: 'reload-16';
  data: string;
} = {
  name: 'reload-16',
  data: `<svg width="16" height="16" xmlns="http://www.w3.org/2000/svg"><path d="M13.266 5.387c-1.28-2.16-3.862-3.246-6.36-2.603a5.734 5.734 0 1 0 7.112 6.298.4.4 0 0 0-.793-.104 4.934 4.934 0 1 1-5.29-5.56 4.914 4.914 0 0 1 4.723 2.518h-2.325a.4.4 0 0 0 0 .8h3.333a.4.4 0 0 0 .4-.4V3.002a.4.4 0 0 0-.8 0v2.385Z"/></svg>`
};
export const iconReload20: {
  name: 'reload-20';
  data: string;
} = {
  name: 'reload-20',
  data: `<svg width="20" height="20" xmlns="http://www.w3.org/2000/svg"><path d="M8.88 4.448c3.01-.774 6.119.778 7.266 3.656Zm-.249-.969c3.489-.896 7.107.901 8.444 4.255a.5.5 0 1 1-.93.37M8.632 3.48Zm.25.969a6.167 6.167 0 1 0 7.65 6.774.5.5 0 0 1 .992.13A7.167 7.167 0 1 1 8.63 3.479"/><path d="M17.083 3.253a.5.5 0 0 1 .5.5v4.166a.5.5 0 0 1-.5.5h-4.167a.5.5 0 0 1 0-1h3.667V3.753a.5.5 0 0 1 .5-.5Z"/></svg>`
};
export const iconReload24: {
  name: 'reload-24';
  data: string;
} = {
  name: 'reload-24',
  data: `<svg width="24" height="24" xmlns="http://www.w3.org/2000/svg"><path d="M10.644 5.289c3.636-.934 7.391.94 8.777 4.418Zm-.274-1.065c4.163-1.07 8.479 1.074 10.073 5.076a.55.55 0 0 1-1.022.407m-9.05-5.483h-.001Zm.273 1.065a7.45 7.45 0 1 0 9.243 8.184.55.55 0 0 1 1.09.143A8.55 8.55 0 1 1 10.37 4.224"/><path d="M20.5 3.953a.55.55 0 0 1 .55.55v5a.55.55 0 0 1-.55.55h-5a.55.55 0 1 1 0-1.1h4.45v-4.45a.55.55 0 0 1 .55-.55Z"/></svg>`
};
export const iconSend16: {
  name: 'send-16';
  data: string;
} = {
  name: 'send-16',
  data: `<svg width="16" height="16" xmlns="http://www.w3.org/2000/svg"><path d="M14.497 1.717a.4.4 0 0 1 0 .566L7.163 9.616a.4.4 0 1 1-.565-.566l7.333-7.333a.4.4 0 0 1 .566 0Z"/><path d="M14.497 1.717a.4.4 0 0 1 .093.419l-4.333 12a.767.767 0 0 1-1.403.038L6.582 9.63 2.04 7.361a.767.767 0 0 1 .038-1.404l12-4.333a.4.4 0 0 1 .419.093ZM2.453 6.672l4.606 2.304a.4.4 0 0 1 .18.178l2.303 4.607 4.006-11.095L2.453 6.672Z"/></svg>`
};
export const iconSend20: {
  name: 'send-20';
  data: string;
} = {
  name: 'send-20',
  data: `<svg width="20" height="20" xmlns="http://www.w3.org/2000/svg"><path d="M18.121 2.146a.5.5 0 0 1 0 .708L8.954 12.02a.5.5 0 1 1-.707-.707l9.167-9.167a.5.5 0 0 1 .707 0Z"/><path d="M18.12 2.146a.5.5 0 0 1 .118.524l-5.417 15a.959.959 0 0 1-1.754.048l-2.839-5.679L2.55 9.2a.958.958 0 0 1 .048-1.754l15-5.416a.5.5 0 0 1 .523.116ZM3.067 8.34l5.758 2.88a.5.5 0 0 1 .224.223l2.879 5.758 5.008-13.869L3.066 8.34Z"/></svg>`
};
export const iconSend32: {
  name: 'send-32';
  data: string;
} = {
  name: 'send-32',
  data: `<svg width="32" height="32" xmlns="http://www.w3.org/2000/svg"><path d="M7.165 5.93a.133.133 0 0 0-.157.175.576.576 0 0 1 .007.02l3.163 9.489h17.619L7.165 5.93Zm20.632 10.884h-17.62l-3.162 9.49a.666.666 0 0 1-.007.018.133.133 0 0 0 .157.176l20.632-9.684Zm-18.684-.6L5.879 6.512a1.333 1.333 0 0 1 1.764-1.684l23.099 10.843a.6.6 0 0 1 0 1.086L7.643 27.6a1.334 1.334 0 0 1-1.764-1.684l3.234-9.701Z"/></svg>`
};
export const iconStar24: {
  name: 'star-24';
  data: string;
} = {
  name: 'star-24',
  data: `<svg width="24" height="24" xmlns="http://www.w3.org/2000/svg"><path d="m12.095 1.91 1.989 6.122a1.1 1.1 0 0 0 1.046.76h6.436c.034 0 .05.01.06.017a.1.1 0 0 1 .035.052.108.108 0 0 1 .003.062.096.096 0 0 1-.04.05l-5.206 3.783a1.1 1.1 0 0 0-.4 1.23l1.99 6.12c.01.033.006.052.002.063a.108.108 0 0 1-.039.05.107.107 0 0 1-.059.021.096.096 0 0 1-.059-.022l-5.206-3.783a1.1 1.1 0 0 0-1.293 0l-5.207 3.783c-.028.02-.047.023-.06.022a.108.108 0 0 1-.058-.022.108.108 0 0 1-.039-.049c-.004-.011-.008-.03.003-.063l1.989-6.12a1.1 1.1 0 0 0-.4-1.23L2.375 8.973a.098.098 0 0 1-.039-.05.108.108 0 0 1 .003-.062.108.108 0 0 1 .034-.052c.01-.008.027-.017.06-.017H8.87a1.1 1.1 0 0 0 1.046-.76l1.989-6.121c.01-.033.025-.045.035-.053a.108.108 0 0 1 .06-.016c.026 0 .047.007.06.016.01.008.025.02.035.053Z"/></svg>`
};
export const iconStarFilled24: {
  name: 'star-filled-24';
  data: string;
} = {
  name: 'star-filled-24',
  data: `<svg width="24" height="24" xmlns="http://www.w3.org/2000/svg"><path d="M11.43 1.756c.18-.553.961-.553 1.14 0l1.99 6.121a.6.6 0 0 0 .57.415h6.436c.582 0 .823.744.353 1.085l-5.207 3.783a.6.6 0 0 0-.218.671l1.989 6.121c.18.553-.453 1.012-.923.67l-5.207-3.782a.6.6 0 0 0-.705 0L6.44 20.623c-.47.341-1.103-.118-.924-.671l1.99-6.121a.6.6 0 0 0-.219-.67L2.081 9.376c-.47-.341-.228-1.085.353-1.085H8.87a.6.6 0 0 0 .57-.415l1.99-6.12Z"/><path d="m12.095 1.91 1.99 6.122a1.1 1.1 0 0 0 1.045.76h6.436c.034 0 .05.01.06.017a.1.1 0 0 1 .035.052.108.108 0 0 1 .003.062.098.098 0 0 1-.039.05l-5.207 3.783a1.1 1.1 0 0 0-.4 1.23l1.99 6.12c.01.033.006.052.002.063a.108.108 0 0 1-.039.05.107.107 0 0 1-.058.021.097.097 0 0 1-.06-.022l-5.206-3.783a1.1 1.1 0 0 0-1.293 0l-5.207 3.783c-.028.02-.047.023-.06.022a.107.107 0 0 1-.058-.022.108.108 0 0 1-.039-.049c-.004-.011-.008-.03.003-.063l1.989-6.12a1.1 1.1 0 0 0-.4-1.23L2.375 8.973a.098.098 0 0 1-.039-.05.108.108 0 0 1 .003-.062.108.108 0 0 1 .035-.052c.01-.008.026-.017.06-.017H8.87a1.1 1.1 0 0 0 1.046-.76l1.99-6.121a.095.095 0 0 1 .034-.053.108.108 0 0 1 .06-.016c.026 0 .047.007.06.016.01.008.025.02.035.053Z"/></svg>`
};
export const iconThumbsDown16: {
  name: 'thumbs-down-16';
  data: string;
} = {
  name: 'thumbs-down-16',
  data: `<svg width="16" height="16" xmlns="http://www.w3.org/2000/svg"><path d="M8.667 13.27a.933.933 0 0 1-.934-.933v-.667a3.067 3.067 0 0 0-2.666-3.04V5.002a1.6 1.6 0 0 1 1.6-1.6h4.666a.4.4 0 0 0 .034-.001c.15-.013.325.044.499.217.175.175.327.452.41.803l.657 3.284a.934.934 0 0 1-.933.897h-2a.4.4 0 0 0-.4.4v3.334a.934.934 0 0 1-.933.933ZM4.994 3.282A1.066 1.066 0 0 0 4 2.603H2.667A1.067 1.067 0 0 0 1.6 3.67v4.667a1.067 1.067 0 0 0 1.067 1.066h2a2.267 2.267 0 0 1 2.266 2.267v.667a1.734 1.734 0 0 0 3.467 0V9.403H12a1.733 1.733 0 0 0 1.733-1.733.397.397 0 0 0-.008-.079l-.669-3.346c-.109-.464-.32-.888-.625-1.192-.303-.303-.693-.48-1.113-.45H6.667a2.4 2.4 0 0 0-1.673.68Zm-.727 5.321h-1.6a.267.267 0 0 1-.267-.266V3.67a.267.267 0 0 1 .267-.267H4a.267.267 0 0 1 .267.267v4.933Z"/></svg>`
};
export const iconThumbsDown20: {
  name: 'thumbs-down-20';
  data: string;
} = {
  name: 'thumbs-down-20',
  data: `<svg width="20" height="20" xmlns="http://www.w3.org/2000/svg"><path d="M11.588 18.446a1.618 1.618 0 0 1-1.617-1.617v-1.06a4.735 4.735 0 0 0-4.236-4.708v-5.88a2.677 2.677 0 0 1 2.677-2.676h7.411a.5.5 0 0 0 .043-.002c.285-.024.6.086.899.384.3.3.551.766.688 1.342l1.046 5.234a1.617 1.617 0 0 1-1.617 1.572h-3.176a.5.5 0 0 0-.5.5v5.294a1.618 1.618 0 0 1-1.618 1.617ZM5.694 2.706a1.559 1.559 0 0 0-1.518-1.2H2.06A1.559 1.559 0 0 0 .5 3.063v7.412a1.56 1.56 0 0 0 1.559 1.558h3.176a3.735 3.735 0 0 1 3.736 3.736v1.059a2.618 2.618 0 1 0 5.235 0v-4.795h2.676A2.618 2.618 0 0 0 19.5 9.418a.504.504 0 0 0-.01-.098l-1.058-5.294-.004-.016c-.169-.72-.495-1.367-.956-1.83-.46-.46-1.045-.72-1.668-.674H8.412a3.676 3.676 0 0 0-2.718 1.201Zm-.959 8.329H2.06a.559.559 0 0 1-.559-.56v-7.41a.559.559 0 0 1 .559-.559h2.117a.559.559 0 0 1 .56.559v7.97Z"/></svg>`
};
export const iconThumbsDown32: {
  name: 'thumbs-down-32';
  data: string;
} = {
  name: 'thumbs-down-32',
  data: `<svg width="32" height="32" xmlns="http://www.w3.org/2000/svg"><path d="M18.333 25.74a2.067 2.067 0 0 1-2.066-2.067V22.34a5.933 5.933 0 0 0-5.334-5.903v-7.43a3.4 3.4 0 0 1 3.4-3.4h9.334a.407.407 0 0 0 .05-.003c.37-.031.776.112 1.156.492.383.384.701.974.874 1.705l1.319 6.595A2.067 2.067 0 0 1 25 16.405h-4a.6.6 0 0 0-.6.6v6.667a2.066 2.066 0 0 1-2.067 2.067ZM10.894 5.952A1.933 1.933 0 0 0 9 4.406H6.333A1.933 1.933 0 0 0 4.4 6.34v9.333a1.933 1.933 0 0 0 1.933 1.934h4a4.733 4.733 0 0 1 4.734 4.733v1.333a3.267 3.267 0 0 0 6.533 0v-6.066H25a3.267 3.267 0 0 0 3.267-3.267.597.597 0 0 0-.012-.118l-1.333-6.667a.636.636 0 0 0-.005-.019c-.21-.901-.62-1.712-1.196-2.288-.575-.576-1.303-.899-2.078-.842h-9.31a4.6 4.6 0 0 0-3.439 1.546Zm-1.16 10.455h-3.4a.733.733 0 0 1-.734-.734V6.34a.733.733 0 0 1 .733-.734H9a.733.733 0 0 1 .733.734v10.067Z"/></svg>`
};
export const iconThumbsUp16: {
  name: 'thumbs-up-16';
  data: string;
} = {
  name: 'thumbs-up-16',
  data: `<svg width="16" height="16" xmlns="http://www.w3.org/2000/svg"><path d="M8.667 3.4a.933.933 0 0 0-.934.933V5a3.067 3.067 0 0 1-2.666 3.04v3.627a1.6 1.6 0 0 0 1.6 1.6h4.666l.034.001c.15.013.325-.044.499-.217.175-.175.327-.452.41-.803l.657-3.284A.934.934 0 0 0 12 8.067h-2a.4.4 0 0 1-.4-.4V4.333a.933.933 0 0 0-.933-.933Zm-3.673 9.988a1.066 1.066 0 0 1-.994.679H2.667A1.066 1.066 0 0 1 1.6 13V8.333a1.067 1.067 0 0 1 1.067-1.066h2A2.267 2.267 0 0 0 6.933 5v-.667a1.733 1.733 0 1 1 3.467 0v2.934H12A1.733 1.733 0 0 1 13.733 9a.397.397 0 0 1-.008.078l-.669 3.347c-.109.464-.32.887-.625 1.192-.303.303-.693.48-1.113.45H6.667a2.4 2.4 0 0 1-1.673-.68Zm-.727-5.321h-1.6a.267.267 0 0 0-.267.266V13a.267.267 0 0 0 .267.267H4A.267.267 0 0 0 4.267 13V8.067Z"/></svg>`
};
export const iconThumbsUp20: {
  name: 'thumbs-up-20';
  data: string;
} = {
  name: 'thumbs-up-20',
  data: `<svg width="20" height="20" xmlns="http://www.w3.org/2000/svg"><path d="M11.588 2.5a1.618 1.618 0 0 0-1.617 1.618v1.058a4.735 4.735 0 0 1-4.236 4.71v5.879a2.677 2.677 0 0 0 2.677 2.676h7.411a.5.5 0 0 1 .043.002c.285.024.6-.086.899-.384.3-.3.551-.765.688-1.342l1.046-5.234a1.617 1.617 0 0 0-1.617-1.571h-3.176a.5.5 0 0 1-.5-.5V4.118A1.618 1.618 0 0 0 11.588 2.5ZM5.694 18.24a1.559 1.559 0 0 1-1.518 1.201H2.06A1.559 1.559 0 0 1 .5 17.882v-7.411a1.559 1.559 0 0 1 1.559-1.56h3.176a3.735 3.735 0 0 0 3.736-3.735V4.118a2.618 2.618 0 1 1 5.235 0v4.794h2.676a2.618 2.618 0 0 1 2.618 2.617.505.505 0 0 1-.01.098l-1.058 5.295-.004.016c-.169.719-.495 1.367-.956 1.828-.46.46-1.045.72-1.668.675H8.412a3.677 3.677 0 0 1-2.718-1.2Zm-.959-8.328H2.06a.559.559 0 0 0-.559.559v7.411a.559.559 0 0 0 .559.56h2.117a.559.559 0 0 0 .56-.56v-7.97Z"/></svg>`
};
export const iconThumbsUp32: {
  name: 'thumbs-up-32';
  data: string;
} = {
  name: 'thumbs-up-32',
  data: `<svg width="32" height="32" xmlns="http://www.w3.org/2000/svg"><path d="M18.333 5.6a2.067 2.067 0 0 0-2.066 2.067V9a5.933 5.933 0 0 1-5.334 5.903v7.43a3.4 3.4 0 0 0 3.4 3.4h9.334l.05.002c.37.032.776-.111 1.156-.491.383-.384.701-.974.874-1.705l1.319-6.595A2.067 2.067 0 0 0 25 14.934h-4a.6.6 0 0 1-.6-.6V7.666A2.067 2.067 0 0 0 18.333 5.6Zm-7.439 19.788A1.933 1.933 0 0 1 9 26.933H6.333A1.933 1.933 0 0 1 4.4 25v-9.333a1.933 1.933 0 0 1 1.933-1.934h4A4.733 4.733 0 0 0 15.067 9V7.667a3.267 3.267 0 0 1 6.533 0v6.066H25A3.267 3.267 0 0 1 28.267 17c0 .04-.004.079-.012.118l-1.333 6.666a.66.66 0 0 1-.005.02c-.21.9-.62 1.712-1.196 2.288-.575.575-1.303.898-2.078.841h-9.31a4.6 4.6 0 0 1-3.439-1.545Zm-1.16-10.455h-3.4a.733.733 0 0 0-.734.734V25a.734.734 0 0 0 .733.733H9A.733.733 0 0 0 9.733 25V14.933Z"/></svg>`
};
export type WkwIcon =
  | 'adjustments-20'
  | 'alert-triangle-20'
  | 'arrow-down-16'
  | 'arrow-down-24'
  | 'check-16'
  | 'check-24'
  | 'checkbox-20'
  | 'checkbox-24'
  | 'chevron-down-16'
  | 'chevron-right-12'
  | 'chevron-right-16'
  | 'chevron-right-20'
  | 'close-16'
  | 'close-20'
  | 'close-24'
  | 'close-32'
  | 'edit-24'
  | 'filter-20'
  | 'icon-check-32'
  | 'info-16'
  | 'info-20'
  | 'info-24'
  | 'info-32'
  | 'lightbulb-24'
  | 'list-checkbox-20'
  | 'list-checkbox-44'
  | 'list-info-20'
  | 'list-info-44'
  | 'logout-24'
  | 'logout-32'
  | 'mail-16'
  | 'mail-20'
  | 'menu-20'
  | 'menu-32'
  | 'person-16'
  | 'person-20'
  | 'phone-16'
  | 'phone-20'
  | 'reload-12'
  | 'reload-16'
  | 'reload-20'
  | 'reload-24'
  | 'send-16'
  | 'send-20'
  | 'send-32'
  | 'star-24'
  | 'star-filled-24'
  | 'thumbs-down-16'
  | 'thumbs-down-20'
  | 'thumbs-down-32'
  | 'thumbs-up-16'
  | 'thumbs-up-20'
  | 'thumbs-up-32';
export interface WkwIconInterface {
  name: WkwIcon;
  data: string;
}
export type WkwIconInterfaceNameSubset<
  T extends Readonly<WkwIconInterface[]>
> = T[number]['name'];
export const completeIconSet = [
  iconAdjustments20,
  iconAlertTriangle20,
  iconArrowDown16,
  iconArrowDown24,
  iconCheck16,
  iconCheck24,
  iconCheckbox20,
  iconCheckbox24,
  iconChevronDown16,
  iconChevronRight12,
  iconChevronRight16,
  iconChevronRight20,
  iconClose16,
  iconClose20,
  iconClose24,
  iconClose32,
  iconEdit24,
  iconFilter20,
  iconIconCheck32,
  iconInfo16,
  iconInfo20,
  iconInfo24,
  iconInfo32,
  iconLightbulb24,
  iconListCheckbox20,
  iconListCheckbox44,
  iconListInfo20,
  iconListInfo44,
  iconLogout24,
  iconLogout32,
  iconMail16,
  iconMail20,
  iconMenu20,
  iconMenu32,
  iconPerson16,
  iconPerson20,
  iconPhone16,
  iconPhone20,
  iconReload12,
  iconReload16,
  iconReload20,
  iconReload24,
  iconSend16,
  iconSend20,
  iconSend32,
  iconStar24,
  iconStarFilled24,
  iconThumbsDown16,
  iconThumbsDown20,
  iconThumbsDown32,
  iconThumbsUp16,
  iconThumbsUp20,
  iconThumbsUp32
];
