import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { FormControl, ValidationErrors } from '@angular/forms';
import { WkwFormField, WkwFormFieldType } from '@wkw-form';
import { getUniqueId } from '@wkw-helpers';
import { debounceTime, Subscription } from 'rxjs';

@Component({
  selector: 'wkw-form-field',
  templateUrl: './form-field.component.html',
  styleUrls: ['./form-field.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FormFieldComponent implements OnInit, OnDestroy {
  @Input({ required: true }) formControl!: FormControl;
  @Input({ required: true }) formField!: WkwFormField;
  @Input() isDisabled = false;
  @Input() isReadonly = false;

  public htmlId = getUniqueId();

  public isRequired = false;

  @Input() isOptionalVisible = false;

  private subscriptions = new Subscription();

  constructor(private cdRef: ChangeDetectorRef) {}

  ngOnInit(): void {
    this.isRequired = !!this.formField.required;
    this.listenForStatusChanges();
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  private listenForStatusChanges(): void {
    this.subscriptions.add(
      this.formControl.parent?.statusChanges
        .pipe(debounceTime(50))
        .subscribe(() => {
          setTimeout(() => {
            this.cdRef.detectChanges();
          });
        })
    );
  }

  private isTouched(): boolean {
    return this.formControl.touched;
  }

  public getErrors(): ValidationErrors | undefined {
    if (this.formControl?.errors) {
      return this.formControl?.errors;
    }

    return undefined;
  }

  public shouldShowErrors(): boolean {
    return this.isTouched() && !!this.getErrors();
  }

  protected readonly WkwFormFieldType = WkwFormFieldType;
}
