import { Component, OnInit } from '@angular/core';
import { IconsRegistry } from '@wkw-services';
import { completeIconSet } from '@wkw-types';

@Component({
  selector: 'wkw-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  constructor(private iconRegistry: IconsRegistry) {}

  ngOnInit(): void {
    this.init();
  }

  private init(): void {
    this.iconRegistry.registerIcons(completeIconSet);
  }
}
