import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import {
  WkwFormFieldExtComponent,
  WkwFormValue,
  WkwSelectOption,
} from '@wkw-form';
import { compact } from 'lodash-es';
import { debounceTime, Subscription } from 'rxjs';

@Component({
  selector: 'wkw-select',
  templateUrl: './select.component.html',
  styleUrls: ['./select.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SelectComponent<OptionKey extends WkwFormValue>
  extends WkwFormFieldExtComponent
  implements OnInit, OnChanges, OnDestroy
{
  @Input({ required: true }) options!: WkwSelectOption<OptionKey>[];
  @Input() placeholder?: string;
  @Input() isDisabled? = false;

  public _options: WkwSelectOption<OptionKey>[] = [];
  public selectedOption?: WkwSelectOption<OptionKey>;
  public value?: WkwFormValue;

  public isDropdownOpen: boolean = false;

  private subscriptions = new Subscription();

  constructor(private cdRef: ChangeDetectorRef) {
    super();
  }

  ngOnInit(): void {
    this.subscriptions.add(
      this.formControl?.valueChanges.subscribe((value) => {
        this.setSelectedOption(value);
      })
    );

    this.subscriptions.add(
      this.formControl?.statusChanges.pipe(debounceTime(50)).subscribe(() => {
        setTimeout(() => {
          this.cdRef.detectChanges();
        });
      })
    );
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['options']) {
      this._options = compact([
        this.placeholder
          ? {
              key: '' as OptionKey,
              label: this.placeholder,
            }
          : undefined,
        ...this.options,
      ]);

      if (this._options.length > 0) {
        this.selectedOption = this._options[0];
        this.value = this.selectedOption?.key;
      }

      this.cdRef.detectChanges();
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  private setSelectedOption(key: WkwFormValue): void {
    this.selectedOption = this._options.find((item) => item.key === key ?? '');
    this.value = this.selectedOption?.key;
  }

  public onChange(option: WkwFormValue): void {
    const selectedOption = this.options.find(
      (o) => o.key.toString() === option.toString()
    );

    this.formControl?.patchValue(selectedOption?.key ?? null);
    this.formControl?.markAsTouched();
    this.formControl?.markAsDirty();
  }

  public trackById(index: number, item: WkwSelectOption<OptionKey>): OptionKey {
    return item.key;
  }

  public getClasses(): string[] {
    const classes = [];
    if (this.shouldShowErrors()) {
      classes.push('form-error');
    }

    return classes;
  }

  public onBlur(): void {
    this.formControl?.markAsTouched();
  }

  public toggleDropdown(): void {
    this.isDropdownOpen = !this.isDropdownOpen;
  }

  public selectOption(option: WkwSelectOption<OptionKey>): void {
    this.selectedOption = option;
    this.isDropdownOpen = false;
    this.onChange(this.selectedOption?.key);
  }
}
