import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { WKW_ENVIRONMENT } from '@wkw-environment';
import { WkwSchemaToken, WkwUser } from '@wkw-types';
import jwt_decode from 'jwt-decode';
import { map, Observable, tap } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  constructor(private http: HttpClient) {}

  private getDecodedToken(token: string): WkwSchemaToken {
    return jwt_decode<WkwSchemaToken>(token);
  }

  public loginRedirect(): void {
    localStorage.removeItem('token');

    this.redirectToExternalPage(
      `${WKW_ENVIRONMENT.api.authUrl}?redirect=${this.getCurrentEnvUrl()}`
    );
  }

  public loginChatbotRedirect(): void {
    this.redirectToExternalPage(
      `${WKW_ENVIRONMENT.api.authUrl}?redirect=${this.getCurrentEnvUrl()}`
    );
  }

  public saveLogin(token: string): void {
    if (token) {
      localStorage.setItem('token', token);
    }
  }

  public getLogin(): string | null {
    return localStorage.getItem('token');
  }

  public isLoginTokenAvailable(): boolean {
    const token = localStorage.getItem('token');

    return !!token;
  }

  public logoutRedirect(): void {
    localStorage.removeItem('token');
    localStorage.removeItem('tokenLoop');
    this.redirectToExternalPage(`${WKW_ENVIRONMENT.api.authUrl}screen/logout`);
  }

  public getToken(code: string): Observable<string> {
    const url = `${WKW_ENVIRONMENT.api.authUrl}token?code=${code}`;

    return this.http.get<{ token: string }>(url).pipe(
      tap((response) => this.saveLogin(response.token)),
      map((response) => response.token)
    );
  }

  public checkForRoleSelectionUrl(token: string): boolean {
    const decodedToken = this.getDecodedToken(token);

    const roleSelectionClaim =
      'http://schemas.wko.at/ws/2014/02/identity/claims/roleselectionurl';
    const roleIdClaim =
      'http://schemas.wko.at/ws/2011/12/identity/claims/roleid';

    return !!(
      decodedToken.userAttributes[roleSelectionClaim] &&
      !decodedToken.userAttributes[roleIdClaim]
    );
  }

  public redirectRoleSelection(): void {
    const token = this.getLogin();

    if (token) {
      const decodedToken = this.getDecodedToken(token);
      const roleSelectionClaim =
        'http://schemas.wko.at/ws/2014/02/identity/claims/roleselectionurl';

      if (decodedToken?.userAttributes[roleSelectionClaim]) {
        const redirectUrl = decodedToken.userAttributes[roleSelectionClaim][0];
        this.redirectToExternalPage(redirectUrl);
      }
    }
  }

  public checkForRoleSelection(token: string): boolean {
    const decodedToken = this.getDecodedToken(token);
    const roleSelectionClaim =
      'http://schemas.wko.at/ws/2014/02/identity/claims/roleselectionurl';

    if (decodedToken?.userAttributes[roleSelectionClaim]) {
      return true;
    }

    return false;
  }

  public checkForChatbotRole(token: string): boolean {
    const decodedToken = this.getDecodedToken(token);

    const roleSelectionClaim =
      'http://schemas.wko.at/ws/2014/02/identity/claims/roleselectionurl';
    const roleIdClaim =
      'http://schemas.wko.at/ws/2011/12/identity/claims/roleid';
    const roleClaim =
      'http://schemas.microsoft.com/ws/2008/06/identity/claims/role';

    return !!(
      decodedToken.userAttributes[roleSelectionClaim] &&
      decodedToken.userAttributes[roleIdClaim] &&
      decodedToken.userAttributes[roleClaim] &&
      decodedToken.userAttributes[roleClaim]?.includes(
        'ChatbotPilotphaseTeilnehmer'
      )
    );
  }

  public getUser(): WkwUser | undefined {
    const token = this.getLogin();

    if (token) {
      const decodedToken = this.getDecodedToken(token);

      const givenNameClaim =
        'http://schemas.xmlsoap.org/ws/2005/05/identity/claims/givenname';
      const surNameClaim =
        'http://schemas.xmlsoap.org/ws/2005/05/identity/claims/surname';
      const emailClaim =
        'http://schemas.xmlsoap.org/ws/2005/05/identity/claims/emailaddress';

      if (
        decodedToken.userAttributes[givenNameClaim] &&
        decodedToken.userAttributes[surNameClaim] &&
        decodedToken.userAttributes[emailClaim]
      ) {
        return {
          firstname: decodedToken.userAttributes[givenNameClaim][0],
          lastname: decodedToken.userAttributes[surNameClaim][0],
          email: decodedToken.userAttributes[emailClaim][0],
        };
      }

      return;
    }
    return;
  }

  public getCurrentEnvUrl(): string {
    switch (WKW_ENVIRONMENT.env) {
      case 'local':
        return 'http://localhost:4200/login';
      case 'dev':
        return 'https://wkw-dev.dev.netural.com/login';
      case 'qa':
        return 'https://wkw-qa.dev.netural.com/login';
      case 'prod':
        return 'https://chatbot.wkw.at/login';
      default:
        throw new Error('Unknown environment: ' + WKW_ENVIRONMENT.env);
    }
  }

  public isTokenExpired(): boolean {
    const token = this.getLogin();
    if (!token) return true;

    const decodedToken = this.getDecodedToken(token);
    const currentTime = Math.floor(new Date().getTime() / 1000); // current time in seconds

    return !!(decodedToken.exp && decodedToken.exp < currentTime);
  }

  public isLoggedIn(): boolean {
    const token = this.getLogin();
    if (token) {
      return (
        this.isLoginTokenAvailable() &&
        !this.isTokenExpired() &&
        this.checkForChatbotRole(token)
      );
    }
    return false;
  }

  public redirectToExternalPage(url: string): void {
    window.location.href = url;
  }
}
