<div>
  <label [for]="htmlId" class="flex items-baseline mb-2 font-medium" *ngIf="formField.label">
    <span class="mr-1" [innerHTML]="formField.label | translate"></span>
    <span *ngIf="isOptionalVisible" class="text-gray-500">(optional)</span>
    <span *ngIf="isRequired && formField.label" class="text-error-500">*</span>
  </label>

  <div class="grow">
    <ng-container *ngIf="formField.type === WkwFormFieldType.Text">
      <wkw-input [formControl]="formControl" [autocomplete]="formField.autocomplete ?? ''" [placeholder]="formField.placeholder ?? ''" [type]="formField.textType ?? 'text'" [htmlId]="htmlId" [isDisabled]="isDisabled"></wkw-input>
    </ng-container>

    <ng-container *ngIf="formField.type === WkwFormFieldType.Textarea">
      <wkw-textarea [formControl]="formControl" [htmlId]="htmlId" [isDisabled]="isDisabled"></wkw-textarea>
    </ng-container>

    <ng-container *ngIf="formField.type === WkwFormFieldType.Select">
      <wkw-select [formControl]="formControl" [options]="formField.options" [placeholder]="formField.placeholder" [htmlId]="htmlId" [isDisabled]="isDisabled"></wkw-select>
    </ng-container>

    <ng-container *ngIf="formField.type === WkwFormFieldType.Checkbox">
      <wkw-checkbox [formControl]="formControl" [innerLabel]="formField.innerLabel" [htmlId]="htmlId" [isReadonly]="isReadonly"></wkw-checkbox>
    </ng-container>

    <ng-container *ngIf="formField.type === WkwFormFieldType.RadioGroup">
      <wkw-radio-group [formControl]="formControl" [options]="formField.options" [htmlId]="htmlId"></wkw-radio-group>
    </ng-container>

    <ng-container *ngIf="formField.type === WkwFormFieldType.Toggle">
      <wkw-toggle [formControl]="formControl" [innerLabel]="formField.innerLabel" [htmlId]="htmlId" [isDisabled]="isDisabled"></wkw-toggle>
    </ng-container>

    <ng-container *ngIf="formField.type === WkwFormFieldType.Rating">
      <wkw-rating [formControl]="formControl" [htmlId]="htmlId" [isDisabled]="isDisabled"></wkw-rating>
    </ng-container>

    <div *ngIf="shouldShowErrors()" class="mt-2">
      <wkw-error [errors]="getErrors()"></wkw-error>
    </div>
  </div>
</div>
