import { DOCUMENT } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  Inject,
  Input,
  Optional,
  ViewChild,
} from '@angular/core';
import { IconsRegistry } from '@wkw-services';
import { WkwIcon } from '@wkw-types';

@Component({
  selector: 'wkw-icon',
  templateUrl: './icon.component.html',
  styleUrls: ['./icon.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class IconComponent {
  private svgIcon?: SVGElement;
  @Input() size?: number;
  @ViewChild('iconContainer', { static: true })
  iconContainer?: ElementRef<HTMLElement>;

  @Input()
  set name(iconName: WkwIcon) {
    setTimeout(() => {
      if (this.svgIcon) {
        this.element.nativeElement.removeChild(this.svgIcon);
      }

      const svgData = this.iconRegistry.getIcon(iconName);

      if (!svgData) {
        console.warn(`no svg for "${iconName}"`);

        return;
      }

      setTimeout(() => {
        this.svgIcon = this.svgElementFromString(svgData);
        this.iconContainer?.nativeElement.appendChild(this.svgIcon);
      }, 0);
    }, 0);
  }

  constructor(
    private element: ElementRef,
    private iconRegistry: IconsRegistry,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    @Optional() @Inject(DOCUMENT) private document: any
  ) {}

  private svgElementFromString(svgContent: string): SVGElement {
    const div = this.document.createElement('DIV');
    div.innerHTML = svgContent;
    return (
      div.querySelector('svg') ||
      this.document.createElementNS('http://www.w3.org/2000/svg', 'path')
    );
  }

  public getIconSizeClasses(): string[] {
    const classes = [];
    switch (this.size) {
      case 16:
        classes.push('w-4', 'h-4');
        break;
      case 20:
        classes.push('w-5', 'h-5');
        break;
      case 24:
        classes.push('w-6', 'h-6');
        break;
      case 32:
        classes.push('w-8', 'h-8');
        break;
    }

    return classes;
  }
}
