import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { WkwFormFieldExtComponent } from '@wkw-form';
import { debounceTime, Subscription } from 'rxjs';

@Component({
  selector: 'wkw-textarea',
  templateUrl: './textarea.component.html',
  styleUrls: ['./textarea.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
// eslint-disable-next-line @typescript-eslint/no-unused-vars
export class TextareaComponent<K extends string>
  extends WkwFormFieldExtComponent
  implements OnInit, OnDestroy
{
  @Input() isDisabled = false;
  @Input() rows = 3;
  @Input() value = '';
  @Input() placeholder = '';

  @ViewChild('textarea') textareaRef?: ElementRef<HTMLInputElement>;

  private subscriptions: Subscription = new Subscription();

  constructor(private cdRef: ChangeDetectorRef) {
    super();
  }

  ngOnInit(): void {
    this.subscriptions.add(
      this.formControl?.valueChanges.subscribe((value) => {
        this.value = value;
      })
    );

    this.value = this.formControl?.value;

    this.subscriptions.add(
      this.formControl?.statusChanges.pipe(debounceTime(50)).subscribe(() => {
        setTimeout(() => {
          this.cdRef.detectChanges();
        });
      })
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  public getClasses(): string[] {
    const classes = [];
    if (this.shouldShowErrors()) {
      classes.push('form-error');
    }
    return classes;
  }

  public onBlur(): void {
    this.formControl?.markAsTouched();
  }

  public onChange(value: string): void {
    this.formControl?.patchValue(value);
    this.formControl?.markAsTouched();
    this.formControl?.markAsDirty();
  }
}
