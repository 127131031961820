<div class="relative z-30">
  <div class="absolute top-0 right-0 mt-8 mr-8">
    <button type="button" (click)="onCloseClick()">
      <wkw-icon name="close-32" [size]="32"></wkw-icon>
    </button>
  </div>
</div>
<div>
  <div class="relative p-8">
    <h4 class="typo-h4 font-bold">
      <ng-content select="#header"></ng-content>
    </h4>
    <div>
      <ng-content></ng-content>
    </div>
  </div>
  <div class="py-4 px-8" *ngIf="isFooterVisible">
    <ng-content select="#footer"></ng-content>
  </div>
</div>
