import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { WkwFormFieldExtComponent } from '@wkw-form';
import { Subscription } from 'rxjs';

@Component({
  selector: 'wkw-checkbox',
  templateUrl: './checkbox.component.html',
  styleUrls: ['./checkbox.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CheckboxComponent
  extends WkwFormFieldExtComponent
  implements OnInit, OnDestroy
{
  @Input() isReadonly = false;
  @Input({ required: true }) innerLabel = '';

  public isChecked = false;
  public isDisabled = false;
  private subscriptions = new Subscription();

  constructor() {
    super();
  }

  ngOnInit(): void {
    this.listenForFormControlChanges();
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  private listenForFormControlChanges(): void {
    this.isChecked = this.formControl?.value;
    this.subscriptions.add(
      this.formControl?.valueChanges.subscribe((value) => {
        this.isChecked = value;
      })
    );
  }

  public onChange(): void {
    this.formControl?.patchValue(!this.isChecked);
    this.formControl?.markAsDirty();
    this.formControl?.markAsTouched();
  }
}
