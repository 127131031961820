import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import {
  getLabelFromKey,
  WkwFormFieldSelect,
  WkwFormFieldType,
} from '@wkw-form';
import { WkwDialogInfoInput } from '@wkw-types';
import { firstValueFrom, Subscription } from 'rxjs';
import { StoreService } from '../../services/store.service';

@Component({
  selector: 'wkw-company-filter-dialog',
  templateUrl: './company-filter-dialog.component.html',
  styleUrls: ['./company-filter-dialog.component.scss'],
})
export class CompanyFilterDialogComponent implements OnInit, OnDestroy {
  public formGroup = new FormGroup({
    sector: new FormControl(''),
    commerceSector: new FormControl(''),
    tradeSector: new FormControl(''),
    trafficSector: new FormControl(''),
    tourismSector: new FormControl(''),
    informationSector: new FormControl(''),
    companySize: new FormControl(''),
  });

  public formFieldSector: WkwFormFieldSelect = {
    type: WkwFormFieldType.Select,
    label: 'company-filter-dialog.sector.label',
    placeholder: 'company-filter-dialog.placeholder',
    key: 'sector',
    options: [
      {
        key: 'commerce-sector',
        label: getLabelFromKey('sector', 'commerce-sector'),
      },
      {
        key: 'industry-sector',
        label: getLabelFromKey('sector', 'industry-sector'),
      },
      {
        key: 'trade-sector',
        label: getLabelFromKey('sector', 'trade-sector'),
      },
      {
        key: 'banking-sector',
        label: getLabelFromKey('sector', 'banking-sector'),
      },
      {
        key: 'traffic-sector',
        label: getLabelFromKey('sector', 'traffic-sector'),
      },
      {
        key: 'tourism-sector',
        label: getLabelFromKey('sector', 'tourism-sector'),
      },
      {
        key: 'information-sector',
        label: getLabelFromKey('sector', 'information-sector'),
      },
    ],
  };

  public formFieldCommerceSector: WkwFormFieldSelect = {
    type: WkwFormFieldType.Select,
    label: 'company-filter-dialog.commerce-sector.label',
    placeholder: 'company-filter-dialog.placeholder',
    key: 'commerce-sector',
    options: [
      {
        key: 'construction',
        label: getLabelFromKey('commerce-sector', 'construction'),
      },
      {
        key: 'roofing',
        label: getLabelFromKey('commerce-sector', 'roofing'),
      },
      {
        key: 'ceramists',
        label: getLabelFromKey('commerce-sector', 'ceramists'),
      },
      {
        key: 'painting',
        label: getLabelFromKey('commerce-sector', 'painting'),
      },
      {
        key: 'construction-auxiliary',
        label: getLabelFromKey('commerce-sector', 'construction-auxiliary'),
      },
      {
        key: 'wood-construction',
        label: getLabelFromKey('commerce-sector', 'wood-construction'),
      },
      {
        key: 'wood-workers',
        label: getLabelFromKey('commerce-sector', 'wood-workers'),
      },
      {
        key: 'metal',
        label: getLabelFromKey('commerce-sector', 'metal'),
      },
      {
        key: 'sanitary',
        label: getLabelFromKey('commerce-sector', 'sanitary'),
      },
      {
        key: 'electrical',
        label: getLabelFromKey('commerce-sector', 'electrical'),
      },
      {
        key: 'plastics',
        label: getLabelFromKey('commerce-sector', 'plastics'),
      },
      {
        key: 'mechatronics',
        label: getLabelFromKey('commerce-sector', 'mechatronics'),
      },
      {
        key: 'automotive',
        label: getLabelFromKey('commerce-sector', 'automotive'),
      },
      {
        key: 'crafts',
        label: getLabelFromKey('commerce-sector', 'crafts'),
      },
      {
        key: 'fashion',
        label: getLabelFromKey('commerce-sector', 'fashion'),
      },
      {
        key: 'healthcare',
        label: getLabelFromKey('commerce-sector', 'healthcare'),
      },
      {
        key: 'food-industry',
        label: getLabelFromKey('commerce-sector', 'food-industry'),
      },
      {
        key: 'cosmeticians',
        label: getLabelFromKey('commerce-sector', 'cosmeticians'),
      },
      {
        key: 'gardeners',
        label: getLabelFromKey('commerce-sector', 'garderners'),
      },
      {
        key: 'photography',
        label: getLabelFromKey('commerce-sector', 'photography'),
      },
      {
        key: 'chemical',
        label: getLabelFromKey('commerce-sector', 'chemical'),
      },
      {
        key: 'hairdressers',
        label: getLabelFromKey('commerce-sector', 'hairdressers'),
      },
      {
        key: 'chimney-sweep',
        label: getLabelFromKey('commerce-sector', 'chimney-sweep'),
      },
      {
        key: 'commercial',
        label: getLabelFromKey('commerce-sector', 'commercial'),
      },
      {
        key: 'personal-care',
        label: getLabelFromKey('commerce-sector', 'personal-care'),
      },
      {
        key: 'service',
        label: getLabelFromKey('commerce-sector', 'personal-service-provider'),
      },
      {
        key: 'film',
        label: getLabelFromKey('commerce-sector', 'film-industry'),
      },
    ],
  };

  public formFieldTradeSector: WkwFormFieldSelect = {
    type: WkwFormFieldType.Select,
    label: 'company-filter-dialog.trade-sector.label',
    placeholder: 'company-filter-dialog.placeholder',
    key: 'trade-sector',
    options: [
      {
        key: 'food-trade',
        label: getLabelFromKey('trade-sector', 'food-trade'),
      },
      {
        key: 'tobacconists',
        label: getLabelFromKey('trade-sector', 'tobacconists'),
      },
      {
        key: 'pharmaceuticals',
        label: getLabelFromKey('trade-sector', 'pharmaceuticals'),
      },
      {
        key: 'agricultural',
        label: getLabelFromKey('trade-sector', 'agricultural'),
      },
      {
        key: 'energy',
        label: getLabelFromKey('trade-sector', 'energy'),
      },
      {
        key: 'market-trade',
        label: getLabelFromKey('trade-sector', 'market-trade'),
      },
      {
        key: 'international-trade',
        label: getLabelFromKey('trade-sector', 'international-trade'),
      },
      {
        key: 'fashion-trade',
        label: getLabelFromKey('trade-sector', 'fashion-trade'),
      },
      {
        key: 'sales',
        label: getLabelFromKey('trade-sector', 'sales'),
      },
      {
        key: 'paper-trade',
        label: getLabelFromKey('trade-sector', 'paper-trade'),
      },
      {
        key: 'commercials-agents',
        label: getLabelFromKey('trade-sector', 'commercial-agents'),
      },
      {
        key: 'antiques',
        label: getLabelFromKey('trade-sector', 'antiques'),
      },
      {
        key: 'wood-trade',
        label: getLabelFromKey('trade-sector', 'wood-trade'),
      },
      {
        key: 'technology-trade',
        label: getLabelFromKey('trade-sector', 'technology-trade'),
      },
      {
        key: 'vehicle-trade',
        label: getLabelFromKey('trade-sector', 'vehicle-trade'),
      },
      {
        key: 'medical-products',
        label: getLabelFromKey('trade-sector', 'medical-products'),
      },
      {
        key: 'electrical-retail',
        label: getLabelFromKey('trade-sector', 'electrical-retail'),
      },
      {
        key: 'internet-trade',
        label: getLabelFromKey('trade-sector', 'internet-trade'),
      },
      {
        key: 'insurance-agents',
        label: getLabelFromKey('trade-sector', 'insurance-agents'),
      },
    ],
  };

  public formFieldTrafficSector: WkwFormFieldSelect = {
    type: WkwFormFieldType.Select,
    label: 'company-filter-dialog.traffic-sector.label',
    placeholder: 'company-filter-dialog.placeholder',
    key: 'traffic-sector',
    options: [
      {
        key: 'railroads',
        label: getLabelFromKey('traffic-sector', 'railroads'),
      },
      {
        key: 'shipping-companies',
        label: getLabelFromKey('traffic-sector', 'shipping-companies'),
      },
      {
        key: 'ropeways',
        label: getLabelFromKey('traffic-sector', 'ropeways'),
      },
      {
        key: 'logistics',
        label: getLabelFromKey('traffic-sector', 'logistics'),
      },
      {
        key: 'transport-industry',
        label: getLabelFromKey('traffic-sector', 'transport-industry'),
      },
      {
        key: 'freight-transport',
        label: getLabelFromKey('traffic-sector', 'freight-transport'),
      },
      {
        key: 'general-traffic',
        label: getLabelFromKey('traffic-sector', 'general-traffic'),
      },
      {
        key: 'service-companies',
        label: getLabelFromKey('traffic-sector', 'service-companies'),
      },
    ],
  };

  public formFieldTourismSector: WkwFormFieldSelect = {
    type: WkwFormFieldType.Select,
    label: 'company-filter-dialog.tourism-sector.label',
    placeholder: 'company-filter-dialog.placeholder',
    key: 'tourism-sector',
    options: [
      {
        key: 'gastronomy',
        label: getLabelFromKey('tourism-sector', 'gastronomy'),
      },
      {
        key: 'hotel',
        label: getLabelFromKey('tourism-sector', 'hotel'),
      },
      {
        key: 'health-companies',
        label: getLabelFromKey('tourism-sector', 'health-companies'),
      },
      {
        key: 'travel-agencies',
        label: getLabelFromKey('tourism-sector', 'travel-agencies'),
      },
      {
        key: 'entertainment',
        label: getLabelFromKey('tourism-sector', 'entertainment'),
      },
      {
        key: 'sport-facilities',
        label: getLabelFromKey('tourism-sector', 'sport-facilities'),
      },
    ],
  };

  public formFieldInformationSector: WkwFormFieldSelect = {
    type: WkwFormFieldType.Select,
    label: 'company-filter-dialog.information-sector.label',
    placeholder: 'company-filter-dialog.placeholder',
    key: 'information-sector',
    options: [
      {
        key: 'resource-management',
        label: getLabelFromKey('information-sector', 'resource-management'),
      },
      {
        key: 'financial-service',
        label: getLabelFromKey('information-sector', 'financial-service'),
      },
      {
        key: 'advertising',
        label: getLabelFromKey('information-sector', 'advertising'),
      },
      {
        key: 'accounting',
        label: getLabelFromKey('information-sector', 'accounting'),
      },
      {
        key: 'engineering-offices',
        label: getLabelFromKey('information-sector', 'engineering-offices'),
      },
      {
        key: 'real-estate',
        label: getLabelFromKey('information-sector', 'real-estate'),
      },
      {
        key: 'media-industry',
        label: getLabelFromKey('information-sector', 'media-industry'),
      },
      {
        key: 'insurance-consultant',
        label: getLabelFromKey('information-sector', 'insurance-consultant'),
      },
      {
        key: 'telecommunications',
        label: getLabelFromKey('information-sector', 'telecommunications'),
      },
    ],
  };

  public formFieldCompanySize: WkwFormFieldSelect = {
    type: WkwFormFieldType.Select,
    label: 'company-filter-dialog.company-size.label',
    placeholder: 'company-filter-dialog.placeholder',
    key: 'company-size',
    options: [
      {
        key: 'one-person',
        label: getLabelFromKey('company-size', 'one-person'),
      },
      {
        key: 'micro',
        label: getLabelFromKey('company-size', 'micro'),
      },
      {
        key: 'small',
        label: getLabelFromKey('company-size', 'small'),
      },
      {
        key: 'medium',
        label: getLabelFromKey('company-size', 'medium'),
      },
      {
        key: 'large',
        label: getLabelFromKey('company-size', 'large'),
      },
    ],
  };

  private subscriptions = new Subscription();

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: WkwDialogInfoInput,
    private dialogRef: MatDialogRef<WkwDialogInfoInput>,
    private store: StoreService,
    private translateService: TranslateService
  ) {}

  ngOnInit(): void {
    this.setFormData();
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  private async setFormData(): Promise<void> {
    const companyData = await firstValueFrom(this.store.company$);
    this.formGroup.setValue({
      sector: companyData?.industry_type.key ?? '',
      commerceSector: companyData?.industry_subtype.key ?? '',
      tradeSector: companyData?.industry_subtype.key ?? '',
      trafficSector: companyData?.industry_subtype.key ?? '',
      tourismSector: companyData?.industry_subtype.key ?? '',
      informationSector: companyData?.industry_subtype.key ?? '',
      companySize: companyData?.company_size.key ?? '',
    });
  }

  public onCancelClick(): void {
    this.dialogRef.close();
  }

  public onResetClick(): void {
    this.formGroup.reset({
      sector: '',
      commerceSector: '',
      tradeSector: '',
      trafficSector: '',
      tourismSector: '',
      informationSector: '',
      companySize: '',
    });
  }

  public async onSubmitClick(): Promise<void> {
    this.formGroup.updateValueAndValidity();
    this.formGroup.markAllAsTouched();
    this.formGroup.markAsDirty();
    if (!this.formGroup.valid) {
      return;
    }

    const sector = this.formGroup.value.sector;
    let selectedSubSector = '';

    switch (sector) {
      case 'commerce-sector':
        selectedSubSector = this.formGroup.value.commerceSector ?? '';
        break;
      case 'trade-sector':
        selectedSubSector = this.formGroup.value.tradeSector ?? '';
        break;
      case 'traffic-sector':
        selectedSubSector = this.formGroup.value.trafficSector ?? '';
        break;
      case 'tourism-sector':
        selectedSubSector = this.formGroup.value.tourismSector ?? '';
        break;
      case 'information-sector':
        selectedSubSector = this.formGroup.value.informationSector ?? '';
        break;
    }

    let industryType = '';
    let industrySubtype = '';
    let companySize = '';

    if (this.formGroup.value.sector) {
      const industryLabel = getLabelFromKey(
        'sector',
        this.formGroup.value.sector
      );

      industryType = this.translateService.instant(industryLabel);
    }

    if (this.formGroup.value.sector && selectedSubSector !== '') {
      const industrySubtypeLabel = getLabelFromKey(
        this.formGroup.value.sector,
        selectedSubSector
      );

      industrySubtype = this.translateService.instant(industrySubtypeLabel);
    }

    if (this.formGroup.value.companySize) {
      const companySizeLabel = getLabelFromKey(
        'company-size',
        this.formGroup.value.companySize
      );

      companySize = this.translateService.instant(companySizeLabel);
    }

    this.store.setCompany({
      industry_type: {
        key: this.formGroup.value.sector ?? '',
        name: industryType,
      },
      industry_subtype: {
        key: selectedSubSector,
        name: industrySubtype,
      },
      company_size: {
        key: this.formGroup.value.companySize ?? '',
        name: companySize,
      },
    });

    this.formGroup.setValue({
      sector: this.formGroup.value.sector ?? '',
      commerceSector: this.formGroup.value.commerceSector ?? '',
      tradeSector: this.formGroup.value.tradeSector ?? '',
      trafficSector: this.formGroup.value.trafficSector ?? '',
      tourismSector: this.formGroup.value.tourismSector ?? '',
      informationSector: this.formGroup.value.informationSector ?? '',
      companySize: this.formGroup.value.companySize ?? '',
    });

    this.dialogRef.close();
  }
}
