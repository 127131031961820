export type WkwFormValue = string | number | boolean;

export type WkwFormField =
  | WkwFormFieldTextarea
  | WkwFormFieldText
  | WkwFormFieldSelect
  | WkwFormFieldCheckbox
  | WkwFormFieldRadioGroup
  | WkwFormFieldToggle
  | WkwFormFieldRating;

// eslint-disable-next-line @typescript-eslint/naming-convention
export enum WkwFormFieldType {
  Text = 'text',
  Textarea = 'textarea',
  Select = 'select',
  Checkbox = 'checkbox',
  RadioGroup = 'radio-group',
  Toggle = 'toggle',
  Rating = 'rating',
}

export type WkwSelectOption<Key extends WkwFormValue = string> = {
  key: Key;
  label: string;
};

type WkwFormFieldBase = {
  label?: string;
  placeholder?: string;
  required?: boolean;
};

type WkwFormFieldTextBase = {
  maxLength?: number;
  autocomplete?: string;
};

export type WkwFormFieldSelect<OptionKey extends WkwFormValue = WkwFormValue> =
  WkwFormFieldBase & {
    type: WkwFormFieldType.Select;
    options: WkwSelectOption<OptionKey>[];
    placeholder?: string;
    label?: string;
    key: string;
  };

export type WkwFormFieldRadioGroup<
  OptionKey extends WkwFormValue = WkwFormValue,
> = WkwFormFieldBase & {
  type: WkwFormFieldType.RadioGroup;
  options: WkwSelectOption<OptionKey>[];
};

export type WkwFormFieldCheckbox = WkwFormFieldBase & {
  type: WkwFormFieldType.Checkbox;
  innerLabel: string;
};

export type WkwFormFieldToggle = WkwFormFieldBase & {
  type: WkwFormFieldType.Toggle;
  innerLabel: string;
};

export type WkwFormFieldRating = WkwFormFieldBase & {
  type: WkwFormFieldType.Rating;
  label?: string;
};

export type WkwFormFieldText = WkwFormFieldBase &
  WkwFormFieldTextBase & {
    type: WkwFormFieldType.Text;
    textType?: WkwFormFieldTextType;
  };

export type WkwFormFieldTextType = 'text' | 'number' | 'date' | 'email';

export type WkwFormFieldTextarea = WkwFormFieldBase &
  WkwFormFieldTextBase & {
    type: WkwFormFieldType.Textarea;
  };

export type WkwHtmlInputEvent = Event & {
  target: HTMLInputElement & EventTarget;
};

export type WkwFormFields<K extends string> = {
  [key in K]: WkwFormField;
};

export type WkwFormFieldVariant = 'column' | 'row';
