import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RadioGroupComponent } from './radio-group.component';
import { RadioModule } from '../radio';

@NgModule({
  declarations: [RadioGroupComponent],
  imports: [CommonModule, RadioModule],
  exports: [RadioGroupComponent],
})
export class RadioGroupModule {}
