import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import {
  WKW_DIALOG_MAX_SIZE,
  WKW_DIALOG_SIZE,
  WkwConversation,
} from '@wkw-types';
import { Subject } from 'rxjs';
import { CompanyFilterDialogComponent } from '../dialogs/company-filter-dialog/company-filter-dialog.component';
import { InfoDialogComponent } from '../dialogs/dialog-info/dialog-info.component';
import { FeedbackDialogComponent } from '../dialogs/feedback-dialog/feedback-dialog.component';

@Injectable({
  providedIn: 'root',
})
export class DialogService {
  feedbackSent = new Subject();

  constructor(
    private translate: TranslateService,
    private dialog: MatDialog
  ) {}

  public openChatbotInfoDialog(
    headerContent: InnerHTML,
    content: InnerHTML
  ): void {
    const data = {
      headerContent,
      content,
    };
    this.dialog.open<InfoDialogComponent>(InfoDialogComponent, {
      width: WKW_DIALOG_SIZE.M,
      maxWidth: WKW_DIALOG_MAX_SIZE.M,
      data,
      autoFocus: false,
      maxHeight: '100vh',
      panelClass: 'wkw-dialog',
    });
  }

  public openChatbotFilterDialog(): void {
    const headline = this.translate.instant('sidebar.specify-company');

    const data = {
      headline,
    };

    this.dialog.open<CompanyFilterDialogComponent>(
      CompanyFilterDialogComponent,
      {
        width: WKW_DIALOG_SIZE.M,
        maxWidth: WKW_DIALOG_MAX_SIZE.M,
        data,
        autoFocus: false,
        maxHeight: '100vh',
        panelClass: 'wkw-dialog',
      }
    );
  }

  public openFeedbackDialog(
    index: number,
    conversations: WkwConversation<Date>[]
  ): void {
    this.dialog.open<FeedbackDialogComponent>(FeedbackDialogComponent, {
      data: { index, conversations },
      width: WKW_DIALOG_SIZE.M,
      maxWidth: WKW_DIALOG_MAX_SIZE.M,
      autoFocus: false,
      maxHeight: '100vh',
      panelClass: 'wkw-dialog',
    });
  }
}
