<wkw-dialog>
  <ng-container id="header">
    <div class="flex">
      <span class="typo-h4 font-bold">{{ data.headline | translate }}</span>
    </div>
  </ng-container>

  <div class="space-y-4 mt-12 mb-6 sm:mb-12">
    <wkw-form-field [formControl]="formGroup.controls.sector" [formField]="formFieldSector"></wkw-form-field>

    <div [ngClass]="{ hidden: formGroup.value.sector !== 'commerce-sector' }">
      <wkw-form-field [formControl]="formGroup.controls.commerceSector" [formField]="formFieldCommerceSector"></wkw-form-field>
    </div>
    <div [ngClass]="{ hidden: formGroup.value.sector !== 'trade-sector' }">
      <wkw-form-field [formControl]="formGroup.controls.tradeSector" [formField]="formFieldTradeSector"></wkw-form-field>
    </div>
    <div [ngClass]="{ hidden: formGroup.value.sector !== 'traffic-sector' }">
      <wkw-form-field [formControl]="formGroup.controls.trafficSector" [formField]="formFieldTrafficSector"></wkw-form-field>
    </div>
    <div [ngClass]="{ hidden: formGroup.value.sector !== 'tourism-sector' }">
      <wkw-form-field [formControl]="formGroup.controls.tourismSector" [formField]="formFieldTourismSector"></wkw-form-field>
    </div>
    <div [ngClass]="{ hidden: formGroup.value.sector !== 'information-sector' }">
      <wkw-form-field [formControl]="formGroup.controls.informationSector" [formField]="formFieldInformationSector"></wkw-form-field>
    </div>

    <wkw-form-field [formControl]="formGroup.controls.companySize" [formField]="formFieldCompanySize"></wkw-form-field>
  </div>

  <div class="flex sm:flex-row flex-col sm:justify-between gap-14">
    <button class="mb-6 sm:mb-0 self-start" (click)="onResetClick()" wkw-button variant="link">{{ 'actions.reset' | translate }}</button>
    <div class="flex md:block space-x-3 justify-end">
      <button wkw-button type="button" variant="outline" (click)="onCancelClick()">{{ 'actions.cancel' | translate }}</button>
      <button wkw-button type="button" (click)="onSubmitClick()">{{ 'actions.apply' | translate }}</button>
    </div>
  </div>
</wkw-dialog>
