import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: '',
    loadChildren: () =>
      import('./wkw-chatbot/wkw-chatbot.module').then(
        (m) => m.WkwChatbotModule
      ),
  },
  // {
  //   todo: 'only add this for dev and qa',
  //   path: 'designsystem',
  //   loadChildren: () =>
  //     import('./designsystem-nds/designsystem-nds.module').then(
  //       (m) => m.DesignsystemNdsModule
  //     ),
  // },
  // {
  //   path: 'preview',
  //   loadChildren: () =>
  //     import('./preview/preview.module').then((m) => m.PreviewModule),
  // },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {})],
  exports: [RouterModule],
})
export class AppRoutingModule {}
