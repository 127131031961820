import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnDestroy,
  OnInit,
} from '@angular/core';
import {
  WkwFormFieldExtComponent,
  WkwFormValue,
  WkwSelectOption,
} from '@wkw-form';
import { getUniqueId } from '@wkw-helpers';

@Component({
  selector: 'wkw-radio-group',
  templateUrl: './radio-group.component.html',
  styleUrls: ['./radio-group.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RadioGroupComponent<OK extends WkwFormValue>
  extends WkwFormFieldExtComponent
  implements OnInit, OnDestroy
{
  @Input({ required: true }) options!: WkwSelectOption<OK>[];

  public groupId = getUniqueId();

  constructor() {
    super();
  }

  ngOnInit(): void {}

  ngOnDestroy(): void {}

  public trackByOption(index: number, option: WkwSelectOption<OK>): string {
    return option.key.toString();
  }
}
