import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { WkwFormFieldExtComponent, WkwFormValue } from '@wkw-form';
import { getUniqueId } from '@wkw-helpers';
import { Subscription } from 'rxjs';

@Component({
  selector: 'wkw-radio',
  templateUrl: './radio.component.html',
  styleUrls: ['./radio.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RadioComponent<OK extends WkwFormValue>
  extends WkwFormFieldExtComponent
  implements OnInit, OnDestroy
{
  @Input({ required: true }) key!: OK;
  @Input({ required: true }) label!: string;
  @Input({ required: true }) groupId!: string;

  public isDisabled = false;

  private subscriptions = new Subscription();

  constructor(private cdRef: ChangeDetectorRef) {
    super();
    this.htmlId = getUniqueId();
  }

  ngOnInit(): void {
    this.listenForFormChange();
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  private listenForFormChange(): void {
    this.subscriptions.add(
      this.formControl?.valueChanges.subscribe(() => {
        this.cdRef.detectChanges();
      })
    );
  }

  public isChecked(): boolean {
    return this.formControl?.value === this.key;
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public onChange(event: any): void {
    const value = event.target.value;

    this.formControl?.patchValue(value);

    this.formControl?.markAsTouched();
    this.formControl?.markAsDirty();
  }
}
