import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit,
} from '@angular/core';
import { WkwIconSize } from '@wkw-types';

@Component({
  selector: 'wkw-spinner',
  templateUrl: './spinner.component.html',
  styleUrls: ['./spinner.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SpinnerComponent implements OnInit {
  @Input() size: WkwIconSize = 24;

  constructor() {}

  ngOnInit(): void {}

  public getWrapperClasses(): string[] {
    const classes = [];

    classes.push('size-' + this.size, 'overflow-hidden');

    return classes;
  }
}
