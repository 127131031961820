<div class="items-top js-error-scroll flex space-x-2 text-error-500 typo-small" *ngIf="errors">
  <div *ngIf="isIconVisible">
    <wkw-icon name="alert-triangle-20"></wkw-icon>
  </div>
  <div>
    <ng-content></ng-content>
    <div *ngIf="errors">
      <div *ngIf="errors['required']">{{ 'errors.required' | translate }}</div>
      <div *ngIf="errors['requiredTrue']">{{ 'errors.required' | translate }}</div>
      <div *ngIf="errors['maxlength']">{{ 'errors.maxlength' | translate }}</div>
      <div *ngIf="errors['minlength']">{{ 'errors.minlength' | translate }}</div>
      <div *ngIf="errors['pattern']">{{ 'errors.pattern' | translate }}</div>
      <div *ngIf="errors['mindate']">{{ 'errors.mindate' | translate }}</div>
      <div *ngIf="errors['mintime']">{{ 'errors.mintime' | translate }}</div>
      <div *ngIf="errors['maxdate']">{{ 'errors.maxdate' | translate }}</div>
      <div *ngIf="errors['min']">{{ 'errors.min' | translate }}</div>
      <div *ngIf="errors['max']">{{ 'errors.max' | translate }}</div>
      <div *ngIf="errors['email']">{{ 'errors.email' | translate }}</div>
      <div *ngIf="errors['url']">{{ 'errors.url' | translate }}</div>
      <div *ngIf="errors['urlHttps']">{{ 'errors.urlHttps' | translate }}</div>
      <div *ngIf="errors['phone']">{{ 'errors.phone' | translate }}</div>
      <div *ngIf="errors['custom']">{{ errors['custom'] | translate }}</div>
    </div>
  </div>
</div>
