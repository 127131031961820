<div class="flex items-center space-x-2 cursor-pointer" (mouseleave)="resetStars()">
  <ng-container *ngFor="let star of stars; let i = index">
    <svg
      class="w-6 h-6 fill-none"
      [ngClass]="{
        'fill-black stroke-gray-300': i < filledStars,
        'fill-none stroke-gray-300': i >= filledStars
      }"
      (mouseover)="fillStars(i + 1)"
      (click)="setRating(i + 1)"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
    >
      <path
        d="M12.0951 1.91074L14.084 8.03174C14.2312 8.48497 14.6536 8.79182 15.1301 8.79182H21.5661C21.6001 8.79182 21.6167 8.80118 21.6267 8.80886C21.6396 8.8189 21.6533 8.83644 21.6612 8.86092C21.6692 8.8854 21.6685 8.90762 21.6639 8.92337C21.6603 8.93542 21.6524 8.95275 21.6249 8.97273L16.4181 12.7557C16.0325 13.0358 15.8712 13.5323 16.0185 13.9855L18.0073 20.1065C18.0178 20.1389 18.014 20.1576 18.0098 20.1694C18.0043 20.1848 17.9918 20.2032 17.971 20.2183C17.9502 20.2335 17.9288 20.2396 17.9124 20.2401C17.8999 20.2405 17.8809 20.2383 17.8534 20.2183L12.6466 16.4354C12.2611 16.1553 11.739 16.1553 11.3535 16.4354L6.14664 20.2183C6.11915 20.2383 6.10021 20.2405 6.08767 20.2401C6.07127 20.2396 6.04991 20.2335 6.02908 20.2183C6.00826 20.2032 5.9958 20.1848 5.99026 20.1694C5.98603 20.1576 5.98225 20.1389 5.99276 20.1065L7.98159 13.9855C8.12885 13.5323 7.96753 13.0358 7.58199 12.7557L2.37516 8.97273C2.34767 8.95275 2.33973 8.93542 2.33622 8.92337C2.33162 8.90762 2.33088 8.8854 2.33883 8.86092C2.34679 8.83644 2.36045 8.8189 2.37342 8.80886C2.38335 8.80118 2.39996 8.79182 2.43394 8.79182H8.86993C9.34648 8.79182 9.76884 8.48496 9.91610 8.03174L11.9049 1.91074C11.9154 1.87843 11.9295 1.86552 11.9398 1.85845C11.9534 1.84922 11.9743 1.84164 12 1.84164C12.0258 1.84164 12.0467 1.84922 12.0602 1.85845C12.0706 1.86552 12.0846 1.87843 12.0951 1.91074Z"
      />
    </svg>
  </ng-container>
  <input class="hidden" [disabled]="isDisabled" [type]="outputType" [id]="htmlId" #input/>
</div>

