import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { NotificationModule } from '@wkw-ui/notification';
import { CheckboxModule } from '../checkbox';
import { ErrorModule } from '../error';
import { InputModule } from '../input';
import { RadioGroupModule } from '../radio-group';
import { SelectModule } from '../select';
import { TextareaModule } from '../textarea';
import { ToggleModule } from '../toggle';
import { FormFieldComponent } from './form-field.component';
import { RatingModule } from '../rating';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  declarations: [FormFieldComponent],
  imports: [
    CommonModule,
    InputModule,
    ErrorModule,
    SelectModule,
    CheckboxModule,
    RadioGroupModule,
    ToggleModule,
    TextareaModule,
    RatingModule,
    NotificationModule,
    TranslateModule,
  ],
  exports: [FormFieldComponent],
})
export class FormFieldModule {}
