export enum WKW_API_ENDPOINTS {
  conversations = 'conversations',
}

export type WkwApiParams = {
  path?: string;
};

export type WkwValidationError = {
  loc: (string | number)[];
  msg: string;
  type: string;
};
