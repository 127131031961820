import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { WKW_ENVIRONMENT } from '@wkw-environment';
import { WKW_API_ENDPOINTS, WkwApiParams } from '@wkw-types';
import { firstValueFrom } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ApiService {
  constructor(private httpClient: HttpClient) {}

  public async get<ResponseData>(
    endpoint: WKW_API_ENDPOINTS,
    params?: WkwApiParams
  ): Promise<ResponseData> {
    return firstValueFrom(
      this.httpClient.get<ResponseData>(
        `${WKW_ENVIRONMENT.api.baseUrl}${endpoint}${params?.path ?? ''}`,
        {
          headers: {
            // Authorization: `Bearer ${token}`,
          },
          responseType: 'json',
        }
      )
    );
  }

  public async getBlob(
    endpoint: WKW_API_ENDPOINTS,
    params?: WkwApiParams
  ): Promise<Blob> {
    return firstValueFrom(
      this.httpClient.get(
        `${WKW_ENVIRONMENT.api.baseUrl}${endpoint}${params?.path ?? ''}`,
        {
          headers: {
            // Authorization: `Bearer ${token}`,
          },
          responseType: 'blob',
        }
      )
    );
  }

  public async post<ResponseData>(
    endpoint: WKW_API_ENDPOINTS,
    data: unknown
  ): Promise<ResponseData> {
    return firstValueFrom(
      this.httpClient.post<ResponseData>(
        `${WKW_ENVIRONMENT.api.baseUrl}${endpoint}`,
        data,
        {
          headers: {
            // Authorization: `Bearer ${token}`,
          },
        }
      )
    );
  }

  public async delete<ResponseData>(
    endpoint: WKW_API_ENDPOINTS,
    params?: WkwApiParams
  ): Promise<ResponseData> {
    return firstValueFrom(
      this.httpClient.delete<ResponseData>(
        `${WKW_ENVIRONMENT.api.baseUrl}${endpoint}${params?.path ?? ''}`,
        {
          headers: {
            // Authorization: `Bearer ${token}`,
          },
        }
      )
    );
  }
}
