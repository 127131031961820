import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ButtonModule } from '@wkw-ui/button';
import { DialogModule } from '@wkw-ui/dialog';
import { InfoDialogComponent } from './dialog-info.component';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  declarations: [InfoDialogComponent],
  imports: [CommonModule, DialogModule, ButtonModule, TranslateModule],
  exports: [InfoDialogComponent],
})
export class InfoDialogModule {}
