import { HttpClient, HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { initializeApp, provideFirebaseApp } from '@angular/fire/app';
import { getFirestore, provideFirestore } from '@angular/fire/firestore';
import { BrowserModule } from '@angular/platform-browser';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { WKW_ENVIRONMENT } from '@wkw-environment';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app.routing';
import { DialogAllModule } from './shared/dialogs/dialog-all.module';

// AoT requires an exported function for factories
export function HttpLoaderFactory(http: HttpClient): TranslateHttpLoader {
  return new TranslateHttpLoader(http);
}

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    HttpClientModule,
    AppRoutingModule,
    // todo: 'only add this for dev and qa',
    // NdsModule.forRoot({
    //   basePath: 'designsystem',
    //   storyblok: {
    //     version: 'draft',
    //     accessToken: '0sykE697bZcfs5PQTlXtHwtt',
    //   },
    //   logLevel: 'TRACE',
    // }),
    DialogAllModule,
    TranslateModule.forRoot({
      defaultLanguage: 'de-DE',
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),

    provideFirebaseApp(() => initializeApp(WKW_ENVIRONMENT.firebaseConfig)),
    provideFirestore(() => getFirestore()),
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
