// Sizes

import { WkwFeedbackItemFirebase } from './chat-item.type';

export enum WKW_DIALOG_SIZE {
  M = '46rem',
}

export enum WKW_DIALOG_MAX_SIZE {
  M = '100vw',
}

export type WkwDialogInfoInput = {
  headline: string;
  content: string | InnerHTML;
  headerContent: InnerHTML;
};

export type WkwDialogFeedbackInput = WkwDialogInfoInput & {
  index: number;
  conversations: WkwFeedbackItemFirebase[];
};
