import {
  ChangeDetectionStrategy,
  Component,
  Inject,
  OnInit,
} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { WkwDialogInfoInput } from '@wkw-types';

@Component({
  selector: 'wkw-dialog-info',
  templateUrl: './dialog-info.component.html',

  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InfoDialogComponent implements OnInit {
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: WkwDialogInfoInput,
    private dialogRef: MatDialogRef<WkwDialogInfoInput>
  ) {}

  ngOnInit(): void {}

  public onCloseClick(): void {
    this.dialogRef.close();
  }
}
