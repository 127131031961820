import {
  ChangeDetectionStrategy,
  Component,
  Inject,
  OnInit,
} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {
  WkwFormFieldRating,
  WkwFormFieldText,
  WkwFormFieldType,
} from '@wkw-form';
import { ChatService, DialogService } from '@wkw-services';
import { WkwDialogFeedbackInput, WkwDialogInfoInput } from '@wkw-types';

@Component({
  selector: 'wkw-feedback-dialog',
  templateUrl: './feedback-dialog.component.html',
  styleUrls: ['./feedback-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FeedbackDialogComponent implements OnInit {
  public formGroup = new FormGroup({
    ratingLanguage: new FormControl(undefined, [Validators.required]),
    ratingContent: new FormControl(undefined, [Validators.required]),
    feedback: new FormControl('', [Validators.required]),
  });

  public formFieldFeedback: WkwFormFieldText = {
    type: WkwFormFieldType.Text,
    placeholder: 'feedback-dialog.input',
    label: 'feedback-dialog.feedback',
    required: true,
  };

  public formFieldRatingContent: WkwFormFieldRating = {
    type: WkwFormFieldType.Rating,
    label: 'feedback-dialog.content-quality',
    required: true,
  };

  public formFieldRatingLanguage: WkwFormFieldRating = {
    type: WkwFormFieldType.Rating,
    label: 'feedback-dialog.language-quality',
    required: true,
  };

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: WkwDialogFeedbackInput,
    private dialogRef: MatDialogRef<WkwDialogInfoInput>,
    private chatService: ChatService,
    private dialogService: DialogService
  ) {}

  ngOnInit(): void {}

  public async onSubmitClick(): Promise<void> {
    this.formGroup.updateValueAndValidity();
    this.formGroup.markAllAsTouched();
    this.formGroup.markAsDirty();
    if (!this.formGroup.valid) {
      return;
    }

    const index = this.data.index;

    const question = this.data.conversations[index - 1].message;
    const message = this.data.conversations[index].message;
    const thumbsUp = false;
    const thumbsDown = true;

    this.chatService.createFeedback({
      question,
      message,
      thumbsUp,
      thumbsDown,
      langQuality: this.formGroup.value.ratingLanguage,
      contentQuality: this.formGroup.value.ratingContent,
      feedback: this.formGroup.value.feedback,
    });

    this.dialogService.feedbackSent.next(true);

    this.chatService.addMessage({
      content: 'chat.message.feedback',
      isUser: false,
      isFeedback: true,
      showThumbs: false,
    });

    this.dialogRef.close();
  }

  public onCancelClick(): void {
    this.dialogRef.close();
  }
}
