import { Injectable } from '@angular/core';
import { addDoc, collection, Firestore } from '@angular/fire/firestore';
import {
  WkwChatItemAuthor,
  WkwChatItemFirebase,
  WkwFeedbackItemFirebase,
} from '@wkw-types';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ChatService {
  public isResponseReceived: boolean = false;
  private conversation: BehaviorSubject<
    {
      author: WkwChatItemAuthor;
      message: string;
      timestamp: Date;
      isFeedback: boolean;
      showThumbs: boolean;
    }[]
  > = new BehaviorSubject<
    {
      author: WkwChatItemAuthor;
      message: string;
      timestamp: Date;
      isFeedback: boolean;
      showThumbs: boolean;
    }[]
  >([]);

  constructor(private angularFirestore: Firestore) {}

  public async createChat(chat: WkwChatItemFirebase): Promise<void> {
    await addDoc(collection(this.angularFirestore, 'chats'), chat);
  }

  public async createFeedback(data: WkwFeedbackItemFirebase): Promise<void> {
    await addDoc(collection(this.angularFirestore, 'feedbacks'), data);
  }

  public resetConversation(): void {
    this.conversation.next([]);
  }

  public getConversation(): Observable<
    {
      author: WkwChatItemAuthor;
      message: string;
      timestamp: Date;
      isFeedback: boolean;
      showThumbs: boolean;
    }[]
  > {
    return this.conversation.asObservable();
  }

  public addMessage(message: {
    content: string;
    isUser: boolean;
    isFeedback?: boolean;
    showThumbs?: boolean;
  }): void {
    const currentConversation = [...this.conversation.value];
    const author = message.isUser ? 'user' : 'WKWBot';
    const timestamp = new Date();

    currentConversation.push({
      author,
      message: message.content,
      timestamp,
      isFeedback: message.isFeedback ?? false,
      showThumbs: message.showThumbs ?? false,
    });

    this.conversation.next(currentConversation);
  }

  public clearConversation(): void {
    this.conversation.next([]);
  }

  public getFullConversation(): {
    author: WkwChatItemAuthor;
    message: string;
    timestamp: Date;
    isFeedback: boolean;
    showThumbs: boolean;
  }[] {
    return this.conversation.value;
  }

  // Update the last message in the conversation
  public updateLastMessage(
    message: string,
    isUser: boolean,
    isFeedback: boolean,
    showThumbs: boolean
  ): void {
    const currentConversation = [...this.conversation.value];
    const author = isUser ? 'user' : 'WKWBot';
    const timestamp = new Date();
    currentConversation[currentConversation.length - 1] = {
      author,
      message,
      timestamp,
      isFeedback,
      showThumbs,
    };
    this.conversation.next(currentConversation);
  }

  public setFooterOffset(): void {
    const chatInputWrapper = document.querySelector(
      '#wkw-chat-input-wrapper'
    ) as HTMLElement;
    const sidebar = document.querySelector('#wkw-sidebar') as HTMLElement;
    const footer = document.querySelector('#wkw-footer') as HTMLElement;
    const footerHeight = footer.offsetHeight;
    chatInputWrapper.style.bottom = `${footerHeight}px`;
    sidebar.style.bottom = `${footerHeight}px`;
  }

  public setHeaderOffset(): void {
    const sidebar = document.querySelector('#wkw-sidebar') as HTMLElement;
    const header = document.querySelector('#wkw-header') as HTMLElement;
    const headerHeight = header.offsetHeight;
    sidebar.style.top = `${headerHeight}px`;
  }
}
